import React from 'react';
import { Stack, Button, Typography, Box } from '@mui/material';
import { styled, keyframes } from '@mui/material/styles';
import { Rocket, Star } from '@mui/icons-material';
import useAuth from '../../../hooks/useAuth';
import { PATH_TC } from '../../../routes/paths';

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const float = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
`;

const StyledBanner = styled(Box)(({ theme }) => ({
  background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
  borderRadius: theme.shape.borderRadius * 2,
  padding: theme.spacing(3),
  position: 'relative',
  overflow: 'hidden',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
  minHeight: '200px', // Ensure minimum height
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  '&:hover': {
    animation: `${pulse} 2s ease-in-out infinite`,
  },
}));

const StyledRocket = styled(Rocket)(({ theme }) => ({
  position: 'absolute',
  top: -10,
  right: -10,
  fontSize: 100,
  color: theme.palette.primary.contrastText,
  opacity: 0.2,
  transform: 'rotate(-45deg)',
}));

const StyledStar = styled(Star)(({ theme }) => ({
  position: 'absolute',
  color: theme.palette.secondary.main,
  animation: `${float} 3s ease-in-out infinite`,
}));

export default function NavbarUpgradeBanner() {
  const { user } = useAuth();

  return (
    <Stack spacing={3} sx={{ px: 1, pb: 5, mt: 1, width: 1, textAlign: 'center', display: 'block' }}>
      <StyledBanner>
        <StyledRocket />
        <StyledStar style={{ top: '10%', left: '10%', fontSize: 20 }} />
        <StyledStar style={{ top: '70%', left: '80%', fontSize: 15 }} />
        <StyledStar style={{ top: '40%', left: '90%', fontSize: 25 }} />
        
        <Typography variant="h6" fontWeight="bold" color="primary.contrastText" gutterBottom>
          Upgrade Your Experience, {user?.displayName}!
        </Typography>
        <Typography variant="body2" color="primary.contrastText" paragraph>
          Unlock premium features with VarsityPro!
        </Typography>
        <Button 
          variant="contained" 
          color="secondary"
          size="medium"
          href={PATH_TC.upgrade}
          sx={{ 
            fontWeight: 'bold',
            px: 3,
            py: 1,
            fontSize: '0.9rem',
            alignSelf: 'center',
            '&:hover': {
              transform: 'translateY(-3px)',
              boxShadow: '0 6px 20px rgba(0, 0, 0, 0.2)',
            },
            transition: 'all 0.3s ease',
          }}
        >
          Upgrade Now
        </Button>
      </StyledBanner>
    </Stack>
  );
}