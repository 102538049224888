import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  // OR
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const logo = (
    <Box sx={{ width: 220, height: 60, display: 'flex', justifyContent: 'center', alignItems: 'center', ...sx }}>
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="100%" viewBox="0 0 1208 94" enableBackground="new 0 0 1208 94" xmlSpace="preserve">
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M1.000000,3.000000 
	C1.332695,2.333333 1.546669,1.216267 2.022062,1.090957 
	C3.112237,0.803594 4.329931,1.000000 5.495853,1.000000 
	C231.684799,1.000000 457.873749,1.000000 684.350586,1.366846 
	C674.721008,2.739026 665.334961,5.266622 657.642517,11.994736 
	C643.593445,24.282644 641.577454,40.331882 643.903992,57.555458 
	C646.360657,75.742531 656.447449,87.496750 674.179688,92.492119 
	C677.739441,93.494942 681.390869,94.172478 685.000000,95.000000 
	C615.312439,95.000000 545.624878,95.000000 475.711792,94.664146 
	C476.805573,94.222244 478.123505,94.047050 479.444366,94.020691 
	C495.124786,93.707733 508.041962,81.509201 509.463623,65.375526 
	C507.526947,65.375526 505.614929,65.375526 503.427887,65.375526 
	C501.667908,76.013916 496.204620,83.862434 485.631866,86.551453 
	C480.098663,87.958740 474.041656,88.321152 468.334076,87.833748 
	C452.535187,86.484596 442.503082,76.223412 444.718933,57.728535 
	C466.328369,57.728535 488.029541,57.728535 509.651581,57.728535 
	C510.926575,43.475410 503.538086,29.560797 492.795166,24.560459 
	C483.976379,20.455717 474.588776,19.861404 465.459412,21.473099 
	C459.537323,22.518578 453.414459,25.496037 448.616882,29.205540 
	C438.489288,37.036201 436.415314,48.697815 437.239044,60.665066 
	C438.351288,76.824257 444.616180,88.800682 461.985657,93.345711 
	C463.997009,93.872017 465.995544,94.447380 468.000000,95.000000 
	C405.312439,95.000000 342.624878,95.000000 279.711792,94.664146 
	C280.805481,94.222237 282.123291,94.047287 283.444122,94.020638 
	C299.125458,93.704117 312.078186,81.466583 313.453857,65.373314 
	C311.519775,65.373314 309.606598,65.373314 307.430939,65.373314 
	C305.655823,76.014114 300.200348,83.867775 289.623108,86.552795 
	C284.089905,87.957390 278.034698,88.321686 272.328339,87.833633 
	C256.523590,86.481880 246.489197,76.203773 248.724335,57.723152 
	C270.332123,57.723152 292.032471,57.723152 313.654663,57.723152 
	C314.869080,43.857979 308.134247,30.299520 297.660095,25.042475 
	C288.206421,20.297623 278.042755,19.616817 268.021973,21.858179 
	C262.573975,23.076735 257.005554,25.755043 252.590027,29.187977 
	C242.433289,37.084526 240.414444,48.709785 241.236710,60.671700 
	C242.348419,76.844269 248.630600,88.810562 265.985382,93.346283 
	C267.996918,93.872002 269.995453,94.447556 272.000000,95.000000 
	C248.979111,95.000000 225.958206,95.000000 202.711853,94.664116 
	C207.192551,93.525208 212.074570,93.266182 216.570480,91.812813 
	C228.094757,88.087456 237.144424,75.641815 236.290665,65.431046 
	C234.390060,65.431046 232.488449,65.431046 230.488083,65.431046 
	C228.540649,78.876930 220.574905,86.150414 207.754425,87.619484 
	C190.745438,89.568512 174.103333,86.344650 171.574097,64.905746 
	C170.706192,57.548885 171.469376,49.522301 173.553558,42.406895 
	C176.640701,31.867462 185.965256,28.515947 196.050797,27.353580 
	C208.539734,25.914219 226.036575,29.631048 229.356277,45.566799 
	C230.366043,50.414024 232.875397,50.681454 236.809738,49.310322 
	C234.932831,37.191925 228.974518,28.327087 217.749893,23.632992 
	C209.057541,19.997877 199.855011,20.125687 190.795380,21.789433 
	C176.891113,24.342863 168.717392,33.139374 165.421661,46.661045 
	C163.327728,55.251980 163.848969,63.901005 166.477707,72.192230 
	C169.347946,81.245171 175.076569,88.209282 184.330490,91.599152 
	C187.832352,92.881943 191.440369,93.874916 195.000000,95.000000 
	C171.979111,95.000000 148.958206,95.000000 125.708786,94.666100 
	C136.809387,94.361473 146.026505,90.114159 153.279266,79.160698 
	C153.279266,84.733223 153.279266,88.627380 153.279266,92.686218 
	C155.690521,92.686218 157.584213,92.686218 159.672485,92.686218 
	C159.672485,69.127007 159.672485,45.889095 159.672485,22.396420 
	C157.485886,22.396420 155.597549,22.396420 153.131821,22.396420 
	C153.131821,26.542505 153.131821,30.400581 153.131821,35.751148 
	C143.320251,21.093819 129.715042,19.669857 115.556793,21.249369 
	C106.074432,22.307230 97.870186,26.582006 92.447914,35.167240 
	C86.682861,44.295223 86.053062,54.404297 87.219070,64.512115 
	C88.856056,78.702576 96.084450,88.978958 110.544975,92.990105 
	C113.025002,93.678032 115.514748,94.330925 118.000000,95.000000 
	C80.326294,95.000000 42.652576,95.010193 4.978949,94.957878 
	C3.652191,94.956039 2.326296,94.333336 1.443728,93.505386 
	C3.672135,93.010773 5.456812,93.010773 7.829689,93.010773 
	C7.829689,83.192520 8.042193,73.907799 7.737253,64.640091 
	C7.591571,60.212551 8.671851,58.614670 13.453238,58.688030 
	C24.400667,58.856003 35.456814,59.151505 46.289543,57.874496 
	C62.147076,56.005142 70.249481,44.290695 68.946106,27.188030 
	C67.817863,12.383328 57.368351,3.000897 41.945297,2.985233 
	C28.296883,2.971372 14.648434,2.993667 1.000000,3.000000 
M429.741028,38.072754 
	C427.743591,31.556444 424.988220,25.765198 418.007507,22.976561 
	C403.299927,17.101194 387.179474,21.249109 377.492035,33.616489 
	C377.354797,33.791695 376.892548,33.712345 376.473175,33.765621 
	C371.903168,23.473425 363.264771,20.973022 352.850067,20.879301 
	C342.742462,20.788347 333.861877,22.854700 326.728333,32.532272 
	C326.728333,28.178343 326.728333,25.283556 326.728333,22.574972 
	C321.424622,21.018471 320.127655,23.091171 320.166992,27.717091 
	C320.338318,47.870770 320.234711,68.026749 320.252899,88.181870 
	C320.254211,89.636772 320.431274,91.091507 320.534180,92.661835 
	C322.790955,92.661835 324.680084,92.661835 327.006317,92.661835 
	C327.006317,90.527702 327.006287,88.728165 327.006287,86.928635 
	C327.006378,74.768921 326.952393,62.608906 327.022095,50.449593 
	C327.088867,38.794411 331.485992,30.663612 339.896576,28.606506 
	C345.433777,27.252182 351.467682,26.698446 357.116638,27.303572 
	C366.964569,28.358498 371.596558,34.018330 371.955872,44.058098 
	C372.253632,52.377815 372.184662,60.711529 372.225830,69.039299 
	C372.264343,76.828835 372.234039,84.618706 372.234039,92.698883 
	C374.683990,92.698883 376.572540,92.698883 379.006317,92.698883 
	C379.006317,90.664528 379.006317,88.876755 379.006348,87.088989 
	C379.006470,74.762711 378.541809,62.414238 379.129028,50.115990 
	C379.801575,36.030651 383.836609,29.614956 396.520782,27.255552 
	C399.269135,26.744329 402.162659,26.915611 404.988220,26.934458 
	C417.638733,27.018833 423.783325,32.851749 423.996887,45.394203 
	C424.235046,59.382458 424.164978,73.375977 424.226685,87.367210 
	C424.234436,89.127403 424.227722,90.887657 424.227722,92.596832 
	C426.904358,92.596832 428.794434,92.596832 431.538361,92.596832 
	C430.979797,74.414597 430.434265,56.656368 429.741028,38.072754 
M578.768250,76.497879 
	C578.507751,66.521072 578.653320,56.512215 577.869263,46.576729 
	C577.129089,37.198833 574.356812,28.207506 564.759155,24.116129 
	C553.983459,19.522572 542.685669,19.147263 531.852722,23.914890 
	C527.868652,25.668285 524.703247,29.281874 520.757446,32.364956 
	C520.757446,28.935942 520.757446,25.695454 520.757446,22.104864 
	C518.660522,22.196611 517.078674,22.265827 515.263306,22.345255 
	C515.263306,45.957867 515.263306,69.196159 515.263306,92.620377 
	C517.237366,92.620377 518.933472,92.620377 521.004028,92.620377 
	C521.004028,84.450218 520.875854,76.641731 521.039795,68.839371 
	C521.207153,60.875980 521.295471,52.882126 522.084595,44.968029 
	C523.028320,35.503143 528.157104,29.511452 537.624207,28.016909 
	C544.024414,27.006542 550.834473,27.387812 557.296082,28.307224 
	C563.900635,29.246965 568.183472,34.001060 569.827881,40.422924 
	C571.010498,45.041252 571.795471,49.876060 571.901123,54.633327 
	C572.180969,67.242874 571.995972,79.862740 571.995972,92.683655 
	C574.451721,92.683655 576.340271,92.683655 578.767822,92.683655 
	C578.767822,87.416435 578.767822,82.456207 578.768250,76.497879 
M595.939758,87.394714 
	C598.469849,89.131096 600.927612,92.226685 603.542786,92.365997 
	C614.243530,92.936028 624.992432,92.603859 636.065735,92.603859 
	C635.907532,90.655075 635.780701,89.092361 635.625427,87.179138 
	C627.205200,87.179138 619.236633,87.197113 611.268188,87.174332 
	C602.419250,87.149033 599.985352,84.844872 599.924927,75.987419 
	C599.825073,61.337162 599.870178,46.685818 599.893921,32.034996 
	C599.895935,30.783920 600.195557,29.533325 600.409302,27.875986 
	C612.354614,27.875986 623.946411,27.875986 635.972107,27.875986 
	C635.869873,25.704805 635.790405,24.018433 635.688721,21.859707 
	C623.522095,21.859707 611.941833,21.859707 599.678467,21.859707 
	C599.678467,15.335176 599.678467,9.293986 599.678467,2.963423 
	C597.062378,3.079637 595.178162,3.163341 592.826538,3.267806 
	C592.826538,9.785473 592.826538,15.702346 592.826538,22.153748 
	C588.749756,22.153748 585.171082,22.153748 581.536499,22.153748 
	C581.536499,24.394716 581.536499,26.078028 581.536499,28.291891 
	C585.566223,28.291891 589.143921,28.291891 593.035522,28.291891 
	C593.035522,44.292221 592.875549,59.760735 593.148132,75.221619 
	C593.216248,79.086014 594.677063,82.925865 595.939758,87.394714 
M80.998512,86.388451 
	C80.998512,58.790791 80.998512,31.193127 80.998512,3.370368 
	C78.792671,3.370368 77.097076,3.370368 75.261665,3.370368 
	C75.261665,33.289433 75.261665,62.853386 75.261665,92.622147 
	C77.220367,92.622147 78.915688,92.622147 80.998512,92.622147 
	C80.998512,90.624725 80.998512,88.986702 80.998512,86.388451 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M774.000000,95.000000 
	C748.645752,95.000000 723.291565,95.000000 697.649658,94.632370 
	C719.268494,92.089920 735.059082,79.323540 736.645996,62.142818 
	C731.644592,62.142818 726.670837,62.259983 721.706360,62.096657 
	C718.951538,62.006020 717.501709,62.900101 715.798889,65.320618 
	C713.631775,68.401291 710.479797,71.622360 707.062012,72.831856 
	C697.284302,76.292030 687.127075,75.998711 677.101990,73.280602 
	C670.452698,71.477760 665.418091,67.159691 664.365601,60.587486 
	C663.150635,53.000595 662.994141,45.039371 663.795776,37.389256 
	C664.680298,28.948042 670.459839,23.297178 678.792297,22.228264 
	C686.761353,21.205971 694.976318,21.472458 703.033447,21.973272 
	C708.984741,22.343187 713.411560,25.930717 716.308472,31.316370 
	C716.895752,32.408180 718.350952,33.690422 719.454529,33.737003 
	C725.191223,33.979168 730.943542,33.849785 737.199646,33.849785 
	C733.754456,20.690622 726.937378,11.061152 714.832214,6.168153 
	C708.837830,3.745196 702.294800,2.679666 696.000000,1.000006 
	C867.000000,1.000000 1038.000000,1.000000 1209.000000,1.000000 
	C1209.000000,7.739794 1209.000000,14.400985 1208.534668,21.591606 
	C1199.448975,22.121037 1190.828613,22.121037 1181.668457,22.121037 
	C1181.668457,15.546503 1181.668457,9.469499 1181.668457,3.112532 
	C1175.382812,3.112532 1169.644165,3.112532 1163.185791,3.112532 
	C1163.185791,9.499571 1163.185791,15.700155 1163.185791,22.411358 
	C1159.731445,22.411358 1156.997314,22.411358 1154.462036,22.411358 
	C1154.462036,27.858908 1154.462036,32.760826 1154.462036,37.660713 
	C1157.623535,37.843185 1160.369873,38.001698 1163.290039,38.170246 
	C1163.290039,41.030693 1163.249146,43.358208 1163.296997,45.683899 
	C1163.492065,55.167416 1163.223145,64.693459 1164.050293,74.122665 
	C1165.090698,85.984467 1172.570312,92.674995 1184.523560,93.078087 
	C1192.672241,93.352890 1200.840454,93.046516 1209.000000,93.000000 
	C1209.611328,96.053101 1207.292725,94.963379 1206.023315,94.966988 
	C1188.771118,95.016083 1171.518921,95.000000 1154.266602,95.000000 
	C1142.156860,95.000000 1130.046997,95.000000 1117.655029,94.628525 
	C1122.693726,93.110733 1128.223755,92.540634 1133.292114,90.699402 
	C1144.202759,86.735809 1151.865845,75.961517 1152.027344,65.149696 
	C1146.723511,65.149696 1141.416748,65.072746 1136.116333,65.220268 
	C1135.156372,65.247002 1133.787476,65.952103 1133.341675,66.752846 
	C1127.514771,77.219391 1117.752563,77.965424 1107.740967,76.435730 
	C1094.748413,74.450531 1088.675415,63.422234 1092.710449,50.781242 
	C1095.292969,42.691086 1101.402710,39.335121 1109.220093,38.581974 
	C1117.949097,37.740990 1126.220581,38.625183 1132.080688,46.460987 
	C1133.071289,47.785446 1134.512695,49.596584 1135.851440,49.691402 
	C1141.131348,50.065365 1146.453491,49.844776 1152.274902,49.844776 
	C1151.051636,40.925163 1147.542480,33.824631 1140.632324,28.754784 
	C1127.765625,19.314892 1113.296509,19.306515 1098.661865,22.779902 
	C1093.981934,23.890648 1089.280151,26.375957 1085.457031,29.349249 
	C1074.938965,37.529110 1072.949341,49.176979 1073.783569,61.668999 
	C1074.870850,77.950356 1083.423218,88.504532 1099.124634,92.946327 
	C1101.425903,93.597366 1103.708618,94.313797 1106.000000,94.999985 
	C1082.312378,95.000000 1058.624878,95.000000 1034.643555,94.637627 
	C1038.865967,93.238480 1043.550537,92.659538 1047.865234,91.074341 
	C1058.455688,87.183487 1064.873779,79.501228 1067.441406,67.834961 
	C1061.286255,67.834961 1055.651733,67.785873 1050.019775,67.886566 
	C1049.149536,67.902122 1047.969116,68.364952 1047.477661,69.026321 
	C1041.892700,76.541588 1033.895020,77.276489 1025.529175,76.984238 
	C1015.195740,76.623245 1008.103821,70.239983 1007.924316,60.612408 
	C1027.985840,60.612408 1048.048096,60.612408 1067.988770,60.612408 
	C1070.592651,46.201389 1062.781982,30.912615 1050.440552,25.155279 
	C1040.398438,20.470575 1029.728149,19.905243 1019.158203,21.449554 
	C1005.188232,23.490633 994.351379,30.609230 990.646423,45.048302 
	C989.110474,51.034344 988.741394,57.632320 989.366333,63.797466 
	C990.824341,78.182083 998.616333,88.013229 1012.721863,92.378952 
	C1015.486816,93.234703 1018.240906,94.125412 1021.000000,95.000000 
	C943.645752,95.000000 866.291565,95.000000 788.633911,94.641068 
	C788.983093,94.179634 789.635132,94.074509 790.288147,93.974991 
	C804.104065,91.869667 815.113464,85.673820 819.095825,71.493713 
	C823.348022,56.352757 822.732971,41.448902 810.197327,30.025923 
	C799.068115,19.884449 785.009338,19.868349 771.080872,21.649580 
	C764.127197,22.538845 757.653625,25.204241 752.339905,30.190645 
	C741.391602,40.464539 740.316101,53.471291 742.600037,67.033257 
	C744.776489,79.957001 752.642395,88.396225 765.262817,92.258408 
	C768.181519,93.151604 771.087952,94.085068 774.000000,95.000000 
M844.679688,23.694126 
	C838.754333,22.968483 832.828979,22.242840 826.673096,21.488966 
	C826.673096,46.160133 826.673096,69.414246 826.673096,92.894394 
	C832.701172,92.894394 838.440857,92.894394 844.743408,92.894394 
	C844.743408,90.829842 844.740906,89.044128 844.743774,87.258430 
	C844.761597,76.096970 844.655640,64.933411 844.843689,53.774803 
	C844.966492,46.485180 848.813354,40.892384 855.871887,39.663273 
	C861.406311,38.699547 867.426086,38.991077 872.976013,40.053162 
	C879.277893,41.259129 882.963684,46.083466 883.285706,52.568264 
	C883.722778,61.373119 883.644409,70.204407 883.743408,79.024849 
	C883.794922,83.619576 883.752441,88.215355 883.752441,92.775482 
	C890.164246,92.775482 895.874512,92.775482 901.725098,92.775482 
	C901.725098,78.902512 902.166077,65.413506 901.599548,51.966938 
	C901.046875,38.850487 895.193237,28.240490 882.401245,23.546658 
	C869.388062,18.771666 856.402893,18.805563 844.727905,29.060671 
	C844.727905,27.166348 844.727905,25.844378 844.679688,23.694126 
M909.000000,44.500053 
	C909.000000,60.593212 909.000000,76.686371 909.000000,92.800354 
	C915.285156,92.800354 920.991821,92.800354 926.988403,92.800354 
	C926.988403,79.804680 926.936584,67.174286 927.007935,54.544575 
	C927.050598,46.991653 931.154541,40.806133 938.354431,39.544712 
	C943.729492,38.602997 949.629211,38.746536 954.959961,39.924450 
	C962.300110,41.546368 965.854126,47.000301 965.968811,54.589325 
	C966.097107,63.077366 966.000549,71.568802 966.001404,80.058762 
	C966.001892,84.326317 966.001465,88.593872 966.001465,92.773766 
	C972.360779,92.773766 978.071289,92.773766 983.928589,92.773766 
	C983.928589,78.578766 984.314514,64.763306 983.819946,50.979443 
	C983.327026,37.243298 975.108948,26.315683 962.432556,22.706282 
	C949.943115,19.150101 937.650269,19.334173 927.159302,29.085951 
	C926.980652,26.589745 926.831604,24.507185 926.681335,22.407349 
	C920.669434,22.407349 915.080139,22.407349 909.594666,22.407349 
	C909.352966,22.950651 909.113464,23.239834 909.111145,23.530891 
	C909.058472,30.187181 909.031616,36.843678 909.000000,44.500053 
z"/>
<path fill="#3C7696" opacity="1.000000" stroke="none" 
	d="
M695.541931,1.000006 
	C702.294800,2.679666 708.837830,3.745196 714.832214,6.168153 
	C726.937378,11.061152 733.754456,20.690622 737.199646,33.849785 
	C730.943542,33.849785 725.191223,33.979168 719.454529,33.737003 
	C718.350952,33.690422 716.895752,32.408180 716.308472,31.316370 
	C713.411560,25.930717 708.984741,22.343187 703.033447,21.973272 
	C694.976318,21.472458 686.761353,21.205971 678.792297,22.228264 
	C670.459839,23.297178 664.680298,28.948042 663.795776,37.389256 
	C662.994141,45.039371 663.150635,53.000595 664.365601,60.587486 
	C665.418091,67.159691 670.452698,71.477760 677.101990,73.280602 
	C687.127075,75.998711 697.284302,76.292030 707.062012,72.831856 
	C710.479797,71.622360 713.631775,68.401291 715.798889,65.320618 
	C717.501709,62.900101 718.951538,62.006020 721.706360,62.096657 
	C726.670837,62.259983 731.644592,62.142818 736.645996,62.142818 
	C735.059082,79.323540 719.268494,92.089920 697.181030,94.632370 
	C693.307068,95.000000 689.614197,95.000000 685.460632,95.000000 
	C681.390869,94.172478 677.739441,93.494942 674.179688,92.492119 
	C656.447449,87.496750 646.360657,75.742531 643.903992,57.555458 
	C641.577454,40.331882 643.593445,24.282644 657.642517,11.994736 
	C665.334961,5.266622 674.721008,2.739026 684.819275,1.366846 
	C688.361267,1.000000 691.722595,1.000000 695.541931,1.000006 
z"/>
<path fill="#3D7796" opacity="1.000000" stroke="none" 
	d="
M1021.463196,95.000000 
	C1018.240906,94.125412 1015.486816,93.234703 1012.721863,92.378952 
	C998.616333,88.013229 990.824341,78.182083 989.366333,63.797466 
	C988.741394,57.632320 989.110474,51.034344 990.646423,45.048302 
	C994.351379,30.609230 1005.188232,23.490633 1019.158203,21.449554 
	C1029.728149,19.905243 1040.398438,20.470575 1050.440552,25.155279 
	C1062.781982,30.912615 1070.592651,46.201389 1067.988770,60.612408 
	C1048.048096,60.612408 1027.985840,60.612408 1007.924316,60.612408 
	C1008.103821,70.239983 1015.195740,76.623245 1025.529175,76.984238 
	C1033.895020,77.276489 1041.892700,76.541588 1047.477661,69.026321 
	C1047.969116,68.364952 1049.149536,67.902122 1050.019775,67.886566 
	C1055.651733,67.785873 1061.286255,67.834961 1067.441406,67.834961 
	C1064.873779,79.501228 1058.455688,87.183487 1047.865234,91.074341 
	C1043.550537,92.659538 1038.865967,93.238480 1034.174805,94.637627 
	C1029.975464,95.000000 1025.950928,95.000000 1021.463196,95.000000 
M1036.143799,38.978176 
	C1021.360291,37.380802 1012.884216,40.042583 1010.718018,46.838501 
	C1022.698120,46.838501 1034.720215,46.838501 1046.742310,46.838501 
	C1047.002441,46.320969 1047.262451,45.803436 1047.522583,45.285908 
	C1043.997925,43.232834 1040.473389,41.179760 1036.143799,38.978176 
z"/>
<path fill="#3B7595" opacity="1.000000" stroke="none" 
	d="
M774.464661,95.000000 
	C771.087952,94.085068 768.181519,93.151604 765.262817,92.258408 
	C752.642395,88.396225 744.776489,79.957001 742.600037,67.033257 
	C740.316101,53.471291 741.391602,40.464539 752.339905,30.190645 
	C757.653625,25.204241 764.127197,22.538845 771.080872,21.649580 
	C785.009338,19.868349 799.068115,19.884449 810.197327,30.025923 
	C822.732971,41.448902 823.348022,56.352757 819.095825,71.493713 
	C815.113464,85.673820 804.104065,91.869667 790.288147,93.974991 
	C789.635132,94.074509 788.983093,94.179634 788.165283,94.641068 
	C783.643127,95.000000 779.286194,95.000000 774.464661,95.000000 
M790.662903,39.532803 
	C785.893616,39.240181 781.026978,38.198322 776.374146,38.802631 
	C764.792542,40.306870 759.502991,47.236477 759.920471,59.098595 
	C760.284546,69.442612 766.558289,75.774948 777.679565,76.873192 
	C779.986450,77.101006 782.342346,77.086853 784.658081,76.928078 
	C794.707458,76.239029 801.201782,70.937729 802.839600,62.215656 
	C804.757324,52.002960 800.809753,44.292763 790.662903,39.532803 
z"/>
<path fill="#3C7695" opacity="1.000000" stroke="none" 
	d="
M1106.458008,94.999985 
	C1103.708618,94.313797 1101.425903,93.597366 1099.124634,92.946327 
	C1083.423218,88.504532 1074.870850,77.950356 1073.783569,61.668999 
	C1072.949341,49.176979 1074.938965,37.529110 1085.457031,29.349249 
	C1089.280151,26.375957 1093.981934,23.890648 1098.661865,22.779902 
	C1113.296509,19.306515 1127.765625,19.314892 1140.632324,28.754784 
	C1147.542480,33.824631 1151.051636,40.925163 1152.274902,49.844776 
	C1146.453491,49.844776 1141.131348,50.065365 1135.851440,49.691402 
	C1134.512695,49.596584 1133.071289,47.785446 1132.080688,46.460987 
	C1126.220581,38.625183 1117.949097,37.740990 1109.220093,38.581974 
	C1101.402710,39.335121 1095.292969,42.691086 1092.710449,50.781242 
	C1088.675415,63.422234 1094.748413,74.450531 1107.740967,76.435730 
	C1117.752563,77.965424 1127.514771,77.219391 1133.341675,66.752846 
	C1133.787476,65.952103 1135.156372,65.247002 1136.116333,65.220268 
	C1141.416748,65.072746 1146.723511,65.149696 1152.027344,65.149696 
	C1151.865845,75.961517 1144.202759,86.735809 1133.292114,90.699402 
	C1128.223755,92.540634 1122.693726,93.110733 1117.186523,94.628525 
	C1113.638672,95.000000 1110.277466,95.000000 1106.458008,94.999985 
z"/>
<path fill="#3F7897" opacity="1.000000" stroke="none" 
	d="
M1209.000000,92.532394 
	C1200.840454,93.046516 1192.672241,93.352890 1184.523560,93.078087 
	C1172.570312,92.674995 1165.090698,85.984467 1164.050293,74.122665 
	C1163.223145,64.693459 1163.492065,55.167416 1163.296997,45.683899 
	C1163.249146,43.358208 1163.290039,41.030693 1163.290039,38.170246 
	C1160.369873,38.001698 1157.623535,37.843185 1154.462036,37.660713 
	C1154.462036,32.760826 1154.462036,27.858908 1154.462036,22.411358 
	C1156.997314,22.411358 1159.731445,22.411358 1163.185791,22.411358 
	C1163.185791,15.700155 1163.185791,9.499571 1163.185791,3.112532 
	C1169.644165,3.112532 1175.382812,3.112532 1181.668457,3.112532 
	C1181.668457,9.469499 1181.668457,15.546503 1181.668457,22.121037 
	C1190.828613,22.121037 1199.448975,22.121037 1208.534668,22.060518 
	C1209.000000,27.022093 1209.000000,32.044186 1208.534424,37.473946 
	C1199.341064,37.881611 1190.613281,37.881611 1181.747803,37.881611 
	C1181.747803,48.615040 1181.699097,58.569862 1181.769043,68.523849 
	C1181.802612,73.304443 1184.412354,76.012596 1189.141235,76.115593 
	C1195.756836,76.259674 1202.380005,76.055298 1209.000000,76.000000 
	C1209.000000,81.354927 1209.000000,86.709862 1209.000000,92.532394 
z"/>
<path fill="#A0ADB6" opacity="1.000000" stroke="none" 
	d="
M468.437500,95.000000 
	C465.995544,94.447380 463.997009,93.872017 461.985657,93.345711 
	C444.616180,88.800682 438.351288,76.824257 437.239044,60.665066 
	C436.415314,48.697815 438.489288,37.036201 448.616882,29.205540 
	C453.414459,25.496037 459.537323,22.518578 465.459412,21.473099 
	C474.588776,19.861404 483.976379,20.455717 492.795166,24.560459 
	C503.538086,29.560797 510.926575,43.475410 509.651581,57.728535 
	C488.029541,57.728535 466.328369,57.728535 444.718933,57.728535 
	C442.503082,76.223412 452.535187,86.484596 468.334076,87.833748 
	C474.041656,88.321152 480.098663,87.958740 485.631866,86.551453 
	C496.204620,83.862434 501.667908,76.013916 503.427887,65.375526 
	C505.614929,65.375526 507.526947,65.375526 509.463623,65.375526 
	C508.041962,81.509201 495.124786,93.707733 479.444366,94.020691 
	C478.123505,94.047050 476.805573,94.222244 475.243164,94.664146 
	C472.958344,95.000000 470.916656,95.000000 468.437500,95.000000 
M479.500000,51.988873 
	C487.257294,51.988873 495.014618,51.988873 503.300262,51.988873 
	C502.053741,42.812538 499.615448,35.222141 491.433411,31.133625 
	C480.751038,25.795706 469.724274,25.826324 458.612335,29.872616 
	C450.114868,32.966885 444.109161,42.277771 444.626984,51.988853 
	C455.900665,51.988853 467.200317,51.988853 479.500000,51.988873 
z"/>
<path fill="#A0ADB6" opacity="1.000000" stroke="none" 
	d="
M272.437500,95.000000 
	C269.995453,94.447556 267.996918,93.872002 265.985382,93.346283 
	C248.630600,88.810562 242.348419,76.844269 241.236710,60.671700 
	C240.414444,48.709785 242.433289,37.084526 252.590027,29.187977 
	C257.005554,25.755043 262.573975,23.076735 268.021973,21.858179 
	C278.042755,19.616817 288.206421,20.297623 297.660095,25.042475 
	C308.134247,30.299520 314.869080,43.857979 313.654663,57.723152 
	C292.032471,57.723152 270.332123,57.723152 248.724335,57.723152 
	C246.489197,76.203773 256.523590,86.481880 272.328339,87.833633 
	C278.034698,88.321686 284.089905,87.957390 289.623108,86.552795 
	C300.200348,83.867775 305.655823,76.014114 307.430939,65.373314 
	C309.606598,65.373314 311.519775,65.373314 313.453857,65.373314 
	C312.078186,81.466583 299.125458,93.704117 283.444122,94.020638 
	C282.123291,94.047287 280.805481,94.222237 279.243134,94.664146 
	C276.958344,95.000000 274.916656,95.000000 272.437500,95.000000 
M274.500000,51.988873 
	C285.253906,51.988873 296.007843,51.988873 307.303040,51.988873 
	C306.037170,42.828175 303.631897,35.231392 295.437134,31.139292 
	C284.751312,25.803249 273.730743,25.823404 262.618164,29.869453 
	C254.115295,32.965317 248.117584,42.255379 248.620300,51.988853 
	C256.894989,51.988853 265.197479,51.988853 274.500000,51.988873 
z"/>
<path fill="#A0ADB6" opacity="1.000000" stroke="none" 
	d="
M118.437500,95.000000 
	C115.514748,94.330925 113.025002,93.678032 110.544975,92.990105 
	C96.084450,88.978958 88.856056,78.702576 87.219070,64.512115 
	C86.053062,54.404297 86.682861,44.295223 92.447914,35.167240 
	C97.870186,26.582006 106.074432,22.307230 115.556793,21.249369 
	C129.715042,19.669857 143.320251,21.093819 153.131821,35.751148 
	C153.131821,30.400581 153.131821,26.542505 153.131821,22.396420 
	C155.597549,22.396420 157.485886,22.396420 159.672485,22.396420 
	C159.672485,45.889095 159.672485,69.127007 159.672485,92.686218 
	C157.584213,92.686218 155.690521,92.686218 153.279266,92.686218 
	C153.279266,88.627380 153.279266,84.733223 153.279266,79.160698 
	C146.026505,90.114159 136.809387,94.361473 125.240128,94.666100 
	C122.958336,95.000000 120.916664,95.000000 118.437500,95.000000 
M101.470093,33.917679 
	C95.415176,39.112930 93.788490,46.409126 93.313744,53.662346 
	C92.490723,66.236710 95.052391,80.017097 107.375153,85.152031 
	C118.085342,89.614998 129.054749,89.405121 139.795242,84.757973 
	C145.828674,82.147469 149.698257,77.498764 151.447754,71.267609 
	C154.065475,61.944092 154.034546,52.556465 151.035248,43.304310 
	C149.345291,38.091160 146.471771,33.453403 141.320679,31.197685 
	C127.920074,25.329409 114.679146,25.114243 101.470093,33.917679 
z"/>
<path fill="#A2AFB8" opacity="1.000000" stroke="none" 
	d="
M1.000000,3.468657 
	C14.648434,2.993667 28.296883,2.971372 41.945297,2.985233 
	C57.368351,3.000897 67.817863,12.383328 68.946106,27.188030 
	C70.249481,44.290695 62.147076,56.005142 46.289543,57.874496 
	C35.456814,59.151505 24.400667,58.856003 13.453238,58.688030 
	C8.671851,58.614670 7.591571,60.212551 7.737253,64.640091 
	C8.042193,73.907799 7.829689,83.192520 7.829689,93.010773 
	C5.456812,93.010773 3.672135,93.010773 1.443729,93.005386 
	C1.000000,63.312439 1.000000,33.624878 1.000000,3.468657 
M7.827845,18.533224 
	C7.827845,29.918653 7.827845,41.304085 7.827845,52.113766 
	C20.876156,52.113766 33.061665,53.039227 45.041439,51.865250 
	C58.212940,50.574486 63.395569,41.579945 62.025234,25.852753 
	C61.169796,16.034971 52.714275,8.964886 41.500111,8.841251 
	C33.179134,8.749514 24.855755,8.758275 16.534527,8.836781 
	C6.433208,8.932079 7.992607,7.445791 7.827845,18.533224 
z"/>
<path fill="#A0ADB6" opacity="1.000000" stroke="none" 
	d="
M195.437500,95.000000 
	C191.440369,93.874916 187.832352,92.881943 184.330490,91.599152 
	C175.076569,88.209282 169.347946,81.245171 166.477707,72.192230 
	C163.848969,63.901005 163.327728,55.251980 165.421661,46.661045 
	C168.717392,33.139374 176.891113,24.342863 190.795380,21.789433 
	C199.855011,20.125687 209.057541,19.997877 217.749893,23.632992 
	C228.974518,28.327087 234.932831,37.191925 236.809738,49.310322 
	C232.875397,50.681454 230.366043,50.414024 229.356277,45.566799 
	C226.036575,29.631048 208.539734,25.914219 196.050797,27.353580 
	C185.965256,28.515947 176.640701,31.867462 173.553558,42.406895 
	C171.469376,49.522301 170.706192,57.548885 171.574097,64.905746 
	C174.103333,86.344650 190.745438,89.568512 207.754425,87.619484 
	C220.574905,86.150414 228.540649,78.876930 230.488083,65.431046 
	C232.488449,65.431046 234.390060,65.431046 236.290665,65.431046 
	C237.144424,75.641815 228.094757,88.087456 216.570480,91.812813 
	C212.074570,93.266182 207.192551,93.525208 202.243195,94.664116 
	C199.958328,95.000000 197.916672,95.000000 195.437500,95.000000 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M1209.000000,75.531342 
	C1202.380005,76.055298 1195.756836,76.259674 1189.141235,76.115593 
	C1184.412354,76.012596 1181.802612,73.304443 1181.769043,68.523849 
	C1181.699097,58.569862 1181.747803,48.615040 1181.747803,37.881611 
	C1190.613281,37.881611 1199.341064,37.881611 1208.534424,37.940804 
	C1209.000000,50.354229 1209.000000,62.708454 1209.000000,75.531342 
z"/>
<path fill="#9EACB5" opacity="1.000000" stroke="none" 
	d="
M429.814880,38.485447 
	C430.434265,56.656368 430.979797,74.414597 431.538361,92.596832 
	C428.794434,92.596832 426.904358,92.596832 424.227722,92.596832 
	C424.227722,90.887657 424.234436,89.127403 424.226685,87.367210 
	C424.164978,73.375977 424.235046,59.382458 423.996887,45.394203 
	C423.783325,32.851749 417.638733,27.018833 404.988220,26.934458 
	C402.162659,26.915611 399.269135,26.744329 396.520782,27.255552 
	C383.836609,29.614956 379.801575,36.030651 379.129028,50.115990 
	C378.541809,62.414238 379.006470,74.762711 379.006348,87.088989 
	C379.006317,88.876755 379.006317,90.664528 379.006317,92.698883 
	C376.572540,92.698883 374.683990,92.698883 372.234039,92.698883 
	C372.234039,84.618706 372.264343,76.828835 372.225830,69.039299 
	C372.184662,60.711529 372.253632,52.377815 371.955872,44.058098 
	C371.596558,34.018330 366.964569,28.358498 357.116638,27.303572 
	C351.467682,26.698446 345.433777,27.252182 339.896576,28.606506 
	C331.485992,30.663612 327.088867,38.794411 327.022095,50.449593 
	C326.952393,62.608906 327.006378,74.768921 327.006287,86.928635 
	C327.006287,88.728165 327.006317,90.527702 327.006317,92.661835 
	C324.680084,92.661835 322.790955,92.661835 320.534180,92.661835 
	C320.431274,91.091507 320.254211,89.636772 320.252899,88.181870 
	C320.234711,68.026749 320.338318,47.870770 320.166992,27.717091 
	C320.127655,23.091171 321.424622,21.018471 326.728333,22.574972 
	C326.728333,25.283556 326.728333,28.178343 326.728333,32.532272 
	C333.861877,22.854700 342.742462,20.788347 352.850067,20.879301 
	C363.264771,20.973022 371.903168,23.473425 376.473175,33.765621 
	C376.892548,33.712345 377.354797,33.791695 377.492035,33.616489 
	C387.179474,21.249109 403.299927,17.101194 418.007507,22.976561 
	C424.988220,25.765198 427.743591,31.556444 429.814880,38.485447 
z"/>
<path fill="#9EABB4" opacity="1.000000" stroke="none" 
	d="
M578.768066,76.996925 
	C578.767822,82.456207 578.767822,87.416435 578.767822,92.683655 
	C576.340271,92.683655 574.451721,92.683655 571.995972,92.683655 
	C571.995972,79.862740 572.180969,67.242874 571.901123,54.633327 
	C571.795471,49.876060 571.010498,45.041252 569.827881,40.422924 
	C568.183472,34.001060 563.900635,29.246965 557.296082,28.307224 
	C550.834473,27.387812 544.024414,27.006542 537.624207,28.016909 
	C528.157104,29.511452 523.028320,35.503143 522.084595,44.968029 
	C521.295471,52.882126 521.207153,60.875980 521.039795,68.839371 
	C520.875854,76.641731 521.004028,84.450218 521.004028,92.620377 
	C518.933472,92.620377 517.237366,92.620377 515.263306,92.620377 
	C515.263306,69.196159 515.263306,45.957867 515.263306,22.345255 
	C517.078674,22.265827 518.660522,22.196611 520.757446,22.104864 
	C520.757446,25.695454 520.757446,28.935942 520.757446,32.364956 
	C524.703247,29.281874 527.868652,25.668285 531.852722,23.914890 
	C542.685669,19.147263 553.983459,19.522572 564.759155,24.116129 
	C574.356812,28.207506 577.129089,37.198833 577.869263,46.576729 
	C578.653320,56.512215 578.507751,66.521072 578.768066,76.996925 
z"/>
<path fill="#A2AFB8" opacity="1.000000" stroke="none" 
	d="
M595.718811,87.085854 
	C594.677063,82.925865 593.216248,79.086014 593.148132,75.221619 
	C592.875549,59.760735 593.035522,44.292221 593.035522,28.291891 
	C589.143921,28.291891 585.566223,28.291891 581.536499,28.291891 
	C581.536499,26.078028 581.536499,24.394716 581.536499,22.153748 
	C585.171082,22.153748 588.749756,22.153748 592.826538,22.153748 
	C592.826538,15.702346 592.826538,9.785473 592.826538,3.267806 
	C595.178162,3.163341 597.062378,3.079637 599.678467,2.963423 
	C599.678467,9.293986 599.678467,15.335176 599.678467,21.859707 
	C611.941833,21.859707 623.522095,21.859707 635.688721,21.859707 
	C635.790405,24.018433 635.869873,25.704805 635.972107,27.875986 
	C623.946411,27.875986 612.354614,27.875986 600.409302,27.875986 
	C600.195557,29.533325 599.895935,30.783920 599.893921,32.034996 
	C599.870178,46.685818 599.825073,61.337162 599.924927,75.987419 
	C599.985352,84.844872 602.419250,87.149033 611.268188,87.174332 
	C619.236633,87.197113 627.205200,87.179138 635.625427,87.179138 
	C635.780701,89.092361 635.907532,90.655075 636.065735,92.603859 
	C624.992432,92.603859 614.243530,92.936028 603.542786,92.365997 
	C600.927612,92.226685 598.469849,89.131096 595.718811,87.085854 
z"/>
<path fill="#9CAAB3" opacity="1.000000" stroke="none" 
	d="
M80.998512,86.868561 
	C80.998512,88.986702 80.998512,90.624725 80.998512,92.622147 
	C78.915688,92.622147 77.220367,92.622147 75.261665,92.622147 
	C75.261665,62.853386 75.261665,33.289433 75.261665,3.370368 
	C77.097076,3.370368 78.792671,3.370368 80.998512,3.370368 
	C80.998512,31.193127 80.998512,58.790791 80.998512,86.868561 
z"/>
<path fill="#3E7897" opacity="1.000000" stroke="none" 
	d="
M844.703796,24.108267 
	C844.727905,25.844378 844.727905,27.166348 844.727905,29.060671 
	C856.402893,18.805563 869.388062,18.771666 882.401245,23.546658 
	C895.193237,28.240490 901.046875,38.850487 901.599548,51.966938 
	C902.166077,65.413506 901.725098,78.902512 901.725098,92.775482 
	C895.874512,92.775482 890.164246,92.775482 883.752441,92.775482 
	C883.752441,88.215355 883.794922,83.619576 883.743408,79.024849 
	C883.644409,70.204407 883.722778,61.373119 883.285706,52.568264 
	C882.963684,46.083466 879.277893,41.259129 872.976013,40.053162 
	C867.426086,38.991077 861.406311,38.699547 855.871887,39.663273 
	C848.813354,40.892384 844.966492,46.485180 844.843689,53.774803 
	C844.655640,64.933411 844.761597,76.096970 844.743774,87.258430 
	C844.740906,89.044128 844.743408,90.829842 844.743408,92.894394 
	C838.440857,92.894394 832.701172,92.894394 826.673096,92.894394 
	C826.673096,69.414246 826.673096,46.160133 826.673096,21.488966 
	C832.828979,22.242840 838.754333,22.968483 844.703796,24.108267 
z"/>
<path fill="#3A7595" opacity="1.000000" stroke="none" 
	d="
M909.000000,44.000095 
	C909.031616,36.843678 909.058472,30.187181 909.111145,23.530891 
	C909.113464,23.239834 909.352966,22.950651 909.594666,22.407349 
	C915.080139,22.407349 920.669434,22.407349 926.681335,22.407349 
	C926.831604,24.507185 926.980652,26.589745 927.159302,29.085951 
	C937.650269,19.334173 949.943115,19.150101 962.432556,22.706282 
	C975.108948,26.315683 983.327026,37.243298 983.819946,50.979443 
	C984.314514,64.763306 983.928589,78.578766 983.928589,92.773766 
	C978.071289,92.773766 972.360779,92.773766 966.001465,92.773766 
	C966.001465,88.593872 966.001892,84.326317 966.001404,80.058762 
	C966.000549,71.568802 966.097107,63.077366 965.968811,54.589325 
	C965.854126,47.000301 962.300110,41.546368 954.959961,39.924450 
	C949.629211,38.746536 943.729492,38.602997 938.354431,39.544712 
	C931.154541,40.806133 927.050598,46.991653 927.007935,54.544575 
	C926.936584,67.174286 926.988403,79.804680 926.988403,92.800354 
	C920.991821,92.800354 915.285156,92.800354 909.000000,92.800354 
	C909.000000,76.686371 909.000000,60.593212 909.000000,44.000095 
z"/>
<path fill="#FBFCFD" opacity="1.000000" stroke="none" 
	d="
M1036.546265,39.052433 
	C1040.473389,41.179760 1043.997925,43.232834 1047.522583,45.285908 
	C1047.262451,45.803436 1047.002441,46.320969 1046.742310,46.838501 
	C1034.720215,46.838501 1022.698120,46.838501 1010.718018,46.838501 
	C1012.884216,40.042583 1021.360291,37.380802 1036.546265,39.052433 
z"/>
<path fill="#FDFDFE" opacity="1.000000" stroke="none" 
	d="
M791.024902,39.689602 
	C800.809753,44.292763 804.757324,52.002960 802.839600,62.215656 
	C801.201782,70.937729 794.707458,76.239029 784.658081,76.928078 
	C782.342346,77.086853 779.986450,77.101006 777.679565,76.873192 
	C766.558289,75.774948 760.284546,69.442612 759.920471,59.098595 
	C759.502991,47.236477 764.792542,40.306870 776.374146,38.802631 
	C781.026978,38.198322 785.893616,39.240181 791.024902,39.689602 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M479.000000,51.988861 
	C467.200317,51.988853 455.900665,51.988853 444.626984,51.988853 
	C444.109161,42.277771 450.114868,32.966885 458.612335,29.872616 
	C469.724274,25.826324 480.751038,25.795706 491.433411,31.133625 
	C499.615448,35.222141 502.053741,42.812538 503.300262,51.988873 
	C495.014618,51.988873 487.257294,51.988873 479.000000,51.988861 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M274.000000,51.988861 
	C265.197479,51.988853 256.894989,51.988853 248.620300,51.988853 
	C248.117584,42.255379 254.115295,32.965317 262.618164,29.869453 
	C273.730743,25.823404 284.751312,25.803249 295.437134,31.139292 
	C303.631897,35.231392 306.037170,42.828175 307.303040,51.988873 
	C296.007843,51.988873 285.253906,51.988873 274.000000,51.988861 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M101.733253,33.660881 
	C114.679146,25.114243 127.920074,25.329409 141.320679,31.197685 
	C146.471771,33.453403 149.345291,38.091160 151.035248,43.304310 
	C154.034546,52.556465 154.065475,61.944092 151.447754,71.267609 
	C149.698257,77.498764 145.828674,82.147469 139.795242,84.757973 
	C129.054749,89.405121 118.085342,89.614998 107.375153,85.152031 
	C95.052391,80.017097 92.490723,66.236710 93.313744,53.662346 
	C93.788490,46.409126 95.415176,39.112930 101.733253,33.660881 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M7.827583,18.038622 
	C7.992607,7.445791 6.433208,8.932079 16.534527,8.836781 
	C24.855755,8.758275 33.179134,8.749514 41.500111,8.841251 
	C52.714275,8.964886 61.169796,16.034971 62.025234,25.852753 
	C63.395569,41.579945 58.212940,50.574486 45.041439,51.865250 
	C33.061665,53.039227 20.876156,52.113766 7.827845,52.113766 
	C7.827845,41.304085 7.827845,29.918653 7.827583,18.038622 
z"/>
</svg>
      {/* <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="100%"
        viewBox="0 0 871 498"
        enableBackground="new 0 0 871 498"
        xmlSpace="preserve"
      >
        <path
          fill="#9D6A57"
          opacity="1.000000"
          stroke="none"
          d="
M480.000000,1.000001 
	C547.354248,1.000000 614.708435,1.000000 682.707031,1.361344 
	C716.324219,5.448396 742.687744,21.286448 764.164307,46.009239 
	C764.254456,46.174801 764.454712,46.209984 764.697327,46.280235 
	C764.739685,46.315311 764.725281,46.424370 764.576538,46.793419 
	C765.169800,48.783638 765.733521,50.518215 766.681091,52.008678 
	C777.863098,69.596642 782.908264,89.046448 783.947815,109.628075 
	C785.023132,130.916580 780.845276,151.277664 772.925354,171.035828 
	C764.213562,192.769562 752.094788,212.355835 735.994873,229.239182 
	C724.184570,241.624100 711.056274,252.502533 696.367981,261.658813 
	C668.704041,278.903778 638.904907,288.474091 606.340515,288.929779 
	C582.848206,289.258514 559.344910,289.191559 535.852844,288.856110 
	C530.373169,288.777893 528.211914,290.561798 527.201965,295.745972 
	C523.736145,313.536560 519.958374,331.267120 516.210571,349.001770 
	C512.633911,365.926361 508.924988,382.822968 505.316772,399.740967 
	C503.771210,406.987549 503.820221,406.993561 496.544342,406.993530 
	C465.048523,406.993439 433.552643,406.973907 402.057007,407.048676 
	C399.994385,407.053558 397.933472,407.788300 395.516083,407.962433 
	C395.234161,405.954102 395.062927,404.116333 395.420013,402.387634 
	C398.571320,387.132599 401.819427,371.897583 405.033417,356.655457 
	C415.607208,306.509888 426.215546,256.371552 436.718170,206.211075 
	C438.686066,196.812500 440.227722,187.324661 441.948914,177.563141 
	C442.674652,175.235428 443.672852,173.277695 444.120758,171.201248 
	C448.532440,150.748703 452.863068,130.278625 457.195374,109.809021 
	C460.117950,96.000221 463.006348,82.184189 465.951355,67.946518 
	C470.661804,45.347767 475.330902,23.173883 480.000000,1.000001 
M670.684143,152.479904 
	C671.624207,149.300919 672.759155,146.164810 673.469116,142.935242 
	C675.993164,131.453644 670.784607,114.882607 656.068604,111.026810 
	C648.482666,109.039192 640.404968,108.348854 632.525513,108.177162 
	C614.215881,107.778198 595.891541,108.018890 577.573486,108.086403 
	C575.100525,108.095520 572.461060,108.160927 570.213318,109.021751 
	C568.563660,109.653511 566.551575,111.434700 566.197083,113.035019 
	C561.560730,133.966324 557.308899,154.982880 552.659912,175.911240 
	C551.677979,180.331482 553.190796,181.103821 557.042786,181.075119 
	C573.028625,180.955917 589.015747,181.009094 605.938416,181.046005 
	C614.269409,181.036652 622.632446,181.464630 630.919800,180.858307 
	C636.112488,180.478394 641.459656,179.185104 646.267334,177.176346 
	C657.463562,172.498383 665.328918,164.141968 670.684143,152.479904 
z"
        />
        <path
          fill="#9D6A57"
          opacity="1.000000"
          stroke="none"
          d="
M1.000000,10.000000 
	C1.000000,8.611003 1.000000,7.222005 1.468648,5.419662 
	C37.075256,5.005901 72.213272,4.985011 107.351021,5.071142 
	C108.952034,5.075067 110.550690,6.043805 112.104828,7.022108 
	C112.156181,9.138382 112.122177,10.815597 112.370178,12.450021 
	C115.181328,30.976307 118.035065,49.496136 120.878510,68.017525 
	C125.713829,99.513336 130.499710,131.016846 135.402847,162.502090 
	C138.849731,184.636124 142.470535,206.743011 145.985458,228.866501 
	C147.424988,237.927048 148.790161,246.999405 150.189163,256.066406 
	C150.651474,256.140991 151.113785,256.215546 151.576111,256.290131 
	C161.055328,239.198654 170.534546,222.107162 180.252655,204.773468 
	C186.798508,193.372086 193.197388,182.263474 199.373810,171.032501 
	C202.483231,165.378464 205.188873,159.502380 208.393494,153.648071 
	C209.335602,152.589371 210.012024,151.637756 210.579712,150.625168 
	C237.027481,103.448166 263.498535,56.284119 289.828400,9.041391 
	C291.509155,6.025650 293.270569,4.896256 296.736450,4.913243 
	C326.397919,5.058620 356.060425,4.985820 385.722534,5.018627 
	C390.873230,5.024323 396.023712,5.219829 401.119141,5.729698 
	C400.368896,7.631949 399.775391,9.189673 398.963531,10.623799 
	C385.606964,34.218208 372.135620,57.748062 358.870331,81.393555 
	C346.342926,103.723778 334.103149,126.215286 321.596100,148.557053 
	C306.334595,175.819244 290.868896,202.967087 275.600189,230.225250 
	C264.077637,250.795685 252.778793,271.491364 241.283203,292.076996 
	C229.937653,312.393921 218.417877,332.613525 207.071915,352.930267 
	C197.503998,370.063080 188.076599,387.274384 178.585114,404.449890 
	C176.131836,408.889252 173.196640,411.269012 167.290039,411.189819 
	C135.645508,410.765747 103.992210,410.976440 72.342064,411.036133 
	C70.248100,411.040070 68.155205,411.610229 65.732674,411.716125 
	C65.055176,410.238953 64.581032,408.982452 64.376625,407.683502 
	C56.591461,358.212036 48.830898,308.736664 41.067406,259.261780 
	C35.266460,222.293732 29.488567,185.322083 23.659151,148.358536 
	C17.472923,109.132500 11.272973,69.908539 4.991576,30.697691 
	C3.880715,23.763271 2.341439,16.897482 1.000000,10.000000 
z"
        />
        <path
          fill="#FFFFFF"
          opacity="1.000000"
          stroke="none"
          d="
M441.966034,177.878021 
	C440.227722,187.324661 438.686066,196.812500 436.718170,206.211075 
	C426.215546,256.371552 415.607208,306.509888 405.033417,356.655457 
	C401.819427,371.897583 398.571320,387.132599 395.420013,402.387634 
	C395.062927,404.116333 395.234161,405.954102 395.665649,408.258057 
	C410.320526,422.896179 424.470154,437.017548 438.770020,451.449280 
	C452.387939,465.155365 465.776245,478.633240 479.421234,491.846008 
	C481.183899,493.552795 484.270599,494.718475 486.751801,494.732208 
	C520.245361,494.918152 553.740295,494.855438 587.234924,494.854309 
	C588.710388,494.854248 590.185852,494.854279 591.770996,494.854279 
	C600.156433,455.312805 608.466370,416.127441 616.795776,376.850128 
	C642.232544,376.850128 667.052002,376.898071 691.871155,376.833405 
	C708.623840,376.789734 725.071228,374.413239 741.086487,369.490082 
	C773.169373,359.627716 800.329468,341.891907 823.281799,317.555176 
	C843.988098,295.599915 858.702271,270.207855 866.715393,241.057739 
	C868.718323,233.771515 870.251160,226.356064 872.000000,219.000000 
	C872.000000,312.287384 872.000000,405.574799 872.000000,499.000000 
	C669.312439,499.000000 466.624878,499.000000 263.439209,498.738098 
	C262.921600,498.301636 262.902161,498.127014 263.075348,497.665710 
	C269.608124,486.066132 275.957458,474.758362 282.287018,463.439514 
	C335.515411,368.253479 388.740173,273.065430 441.966034,177.878021 
z"
        />
        <path
          fill="#374547"
          opacity="1.000000"
          stroke="none"
          d="
M872.000000,218.531342 
	C870.251160,226.356064 868.718323,233.771515 866.715393,241.057739 
	C858.702271,270.207855 843.988098,295.599915 823.281799,317.555176 
	C800.329468,341.891907 773.169373,359.627716 741.086487,369.490082 
	C725.071228,374.413239 708.623840,376.789734 691.871155,376.833405 
	C667.052002,376.898071 642.232544,376.850128 616.795776,376.850128 
	C608.466370,416.127441 600.156433,455.312805 591.770996,494.854279 
	C590.185852,494.854279 588.710388,494.854248 587.234924,494.854309 
	C553.740295,494.855438 520.245361,494.918152 486.751801,494.732208 
	C484.270599,494.718475 481.183899,493.552795 479.421234,491.846008 
	C465.776245,478.633240 452.387939,465.155365 439.260529,451.413208 
	C473.741669,451.040558 507.882660,450.951843 542.023010,451.065277 
	C546.410217,451.079834 548.520569,450.022308 549.328857,445.269379 
	C551.414185,433.007477 554.047241,420.834412 556.641113,408.664185 
	C561.526733,385.740875 566.558472,362.848755 571.486267,339.934387 
	C572.974792,333.012390 572.909424,332.998932 580.215210,332.997040 
	C605.030090,332.990662 629.859985,333.489807 654.653015,332.750610 
	C666.013306,332.411926 677.551270,330.609558 688.570374,327.769928 
	C712.759460,321.536255 734.780273,310.639282 754.787598,295.357147 
	C773.659058,280.942627 789.207886,263.774414 802.096313,243.982193 
	C812.847412,227.472290 819.987427,209.480545 824.380554,190.354111 
	C831.237732,160.499359 829.287048,131.581482 814.974060,103.675049 
	C815.716675,102.189751 816.429443,101.060875 817.482178,99.980362 
	C825.058350,107.090302 832.403137,114.044548 839.508911,121.235046 
	C856.139771,138.064148 866.418152,158.166122 870.207031,181.566422 
	C870.560669,183.750458 871.390991,185.857315 872.000000,188.000000 
	C872.000000,198.020889 872.000000,208.041794 872.000000,218.531342 
z"
        />
        <path
          fill="#FFFFFF"
          opacity="1.000000"
          stroke="none"
          d="
M1.000000,10.468657 
	C2.341439,16.897482 3.880715,23.763271 4.991576,30.697691 
	C11.272973,69.908539 17.472923,109.132500 23.659151,148.358536 
	C29.488567,185.322083 35.266460,222.293732 41.067406,259.261780 
	C48.830898,308.736664 56.591461,358.212036 64.376625,407.683502 
	C64.581032,408.982452 65.055176,410.238953 65.904854,411.999878 
	C80.961449,426.954407 95.516716,441.424286 110.240067,456.182587 
	C124.296997,470.300598 138.185852,484.130249 152.087524,498.222168 
	C152.066910,498.656281 152.033463,498.828156 152.000000,499.000000 
	C101.701096,499.000000 51.402187,499.000000 1.000000,499.000000 
	C1.000000,336.312439 1.000000,173.624878 1.000000,10.468657 
z"
        />
        <path
          fill="#FEFEFE"
          opacity="1.000000"
          stroke="none"
          d="
M401.174286,5.327666 
	C396.023712,5.219829 390.873230,5.024323 385.722534,5.018627 
	C356.060425,4.985820 326.397919,5.058620 296.736450,4.913243 
	C293.270569,4.896256 291.509155,6.025650 289.828400,9.041391 
	C263.498535,56.284119 237.027481,103.448166 210.579712,150.625168 
	C210.012024,151.637756 209.335602,152.589371 208.360382,153.256073 
	C205.014389,133.921722 202.051270,114.895325 198.963684,95.889160 
	C198.690567,94.207893 197.994888,92.271790 196.838043,91.106964 
	C183.383057,77.559082 169.801636,64.136757 156.185898,50.767372 
	C156.118118,50.853554 156.295837,50.982014 156.217728,50.672413 
	C155.797485,50.142879 155.455338,49.922947 155.037170,49.382107 
	C153.562103,47.655472 152.170959,46.241779 150.762772,44.845264 
	C137.893692,32.082870 125.021629,19.323456 112.150444,6.563173 
	C110.550690,6.043805 108.952034,5.075067 107.351021,5.071142 
	C72.213272,4.985011 37.075256,5.005901 1.468648,5.003158 
	C1.000000,3.667875 1.000000,2.335750 1.000000,1.001813 
	C160.354233,1.000000 319.708466,1.000000 479.531342,1.000001 
	C475.330902,23.173883 470.661804,45.347767 465.630554,67.822449 
	C459.131226,62.101109 452.994110,56.078953 446.719910,49.755375 
	C432.866913,35.810287 419.164398,22.153063 405.412384,8.545796 
	C404.162262,7.308806 402.594513,6.392760 401.174286,5.327666 
z"
        />
        <path
          fill="#FFFFFF"
          opacity="1.000000"
          stroke="none"
          d="
M872.000000,187.531342 
	C871.390991,185.857315 870.560669,183.750458 870.207031,181.566422 
	C866.418152,158.166122 856.139771,138.064148 839.508911,121.235046 
	C832.403137,114.044548 825.058350,107.090302 817.417114,99.638123 
	C813.011536,95.160416 809.010986,91.073318 804.880615,86.678085 
	C791.408997,73.054749 778.067139,59.739563 764.725281,46.424370 
	C764.725281,46.424370 764.739685,46.315311 764.644104,46.146343 
	C764.548523,45.977375 764.274475,45.878857 764.274475,45.878857 
	C742.687744,21.286448 716.324219,5.448396 683.175720,1.361344 
	C745.926636,1.000000 808.853271,1.000000 872.000000,1.000000 
	C872.000000,63.020721 872.000000,125.041702 872.000000,187.531342 
z"
        />
        <path
          fill="#676F6E"
          opacity="1.000000"
          stroke="none"
          d="
M152.468658,499.000000 
	C152.033463,498.828156 152.066910,498.656281 152.551605,498.211700 
	C189.629486,497.943451 226.256119,497.947937 262.882751,497.952423 
	C262.902161,498.127014 262.921600,498.301636 262.970520,498.738098 
	C226.312439,499.000000 189.624878,499.000000 152.468658,499.000000 
z"
        />
        <path
          fill="#6C554D"
          opacity="1.000000"
          stroke="none"
          d="
M764.576538,46.793419 
	C778.067139,59.739563 791.408997,73.054749 804.899414,87.049118 
	C808.346741,93.162689 811.645508,98.597076 814.944214,104.031464 
	C829.287048,131.581482 831.237732,160.499359 824.380554,190.354111 
	C819.987427,209.480545 812.847412,227.472290 802.096313,243.982193 
	C789.207886,263.774414 773.659058,280.942627 754.787598,295.357147 
	C734.780273,310.639282 712.759460,321.536255 688.570374,327.769928 
	C677.551270,330.609558 666.013306,332.411926 654.653015,332.750610 
	C629.859985,333.489807 605.030090,332.990662 580.215210,332.997040 
	C572.909424,332.998932 572.974792,333.012390 571.486267,339.934387 
	C566.558472,362.848755 561.526733,385.740875 556.641113,408.664185 
	C554.047241,420.834412 551.414185,433.007477 549.328857,445.269379 
	C548.520569,450.022308 546.410217,451.079834 542.023010,451.065277 
	C507.882660,450.951843 473.741669,451.040558 439.110352,451.102875 
	C424.470154,437.017548 410.320526,422.896179 396.021301,408.479187 
	C397.933472,407.788300 399.994385,407.053558 402.057007,407.048676 
	C433.552643,406.973907 465.048523,406.993439 496.544342,406.993530 
	C503.820221,406.993561 503.771210,406.987549 505.316772,399.740967 
	C508.924988,382.822968 512.633911,365.926361 516.210571,349.001770 
	C519.958374,331.267120 523.736145,313.536560 527.201965,295.745972 
	C528.211914,290.561798 530.373169,288.777893 535.852844,288.856110 
	C559.344910,289.191559 582.848206,289.258514 606.340515,288.929779 
	C638.904907,288.474091 668.704041,278.903778 696.367981,261.658813 
	C711.056274,252.502533 724.184570,241.624100 735.994873,229.239182 
	C752.094788,212.355835 764.213562,192.769562 772.925354,171.035828 
	C780.845276,151.277664 785.023132,130.916580 783.947815,109.628075 
	C782.908264,89.046448 777.863098,69.596642 766.681091,52.008678 
	C765.733521,50.518215 765.169800,48.783638 764.576538,46.793419 
z"
        />
        <path
          fill="#374447"
          opacity="1.000000"
          stroke="none"
          d="
M263.075348,497.665710 
	C226.256119,497.947937 189.629486,497.943451 152.538773,497.949402 
	C138.185852,484.130249 124.296997,470.300598 110.574203,455.947479 
	C112.553650,455.280579 114.366943,455.013580 116.180435,455.012238 
	C147.831833,454.988464 179.485489,454.804688 211.132614,455.173920 
	C216.928528,455.241516 219.801682,453.130981 222.339935,448.439117 
	C230.154053,433.995087 238.258209,419.707458 246.294769,405.384399 
	C261.260406,378.712097 276.266388,352.062500 291.239014,325.394135 
	C300.586121,308.745575 309.927704,292.093872 319.226379,275.418213 
	C329.340363,257.280396 339.377319,239.099609 349.491699,220.962021 
	C365.209900,192.775391 380.922974,164.585724 396.717651,136.441940 
	C410.380585,112.096603 424.099701,87.782295 437.926208,63.529678 
	C440.588745,58.859482 443.864227,54.538742 446.856964,50.056797 
	C452.994110,56.078953 459.131226,62.101109 465.589203,68.247330 
	C463.006348,82.184189 460.117950,96.000221 457.195374,109.809021 
	C452.863068,130.278625 448.532440,150.748703 444.120758,171.201248 
	C443.672852,173.277695 442.674652,175.235428 441.948914,177.563141 
	C388.740173,273.065430 335.515411,368.253479 282.287018,463.439514 
	C275.957458,474.758362 269.608124,486.066132 263.075348,497.665710 
z"
        />
        <path
          fill="#6C554D"
          opacity="1.000000"
          stroke="none"
          d="
M605.002441,180.999008 
	C589.015747,181.009094 573.028625,180.955917 557.042786,181.075119 
	C553.190796,181.103821 551.677979,180.331482 552.659912,175.911240 
	C557.308899,154.982880 561.560730,133.966324 566.197083,113.035019 
	C566.551575,111.434700 568.563660,109.653511 570.213318,109.021751 
	C572.461060,108.160927 575.100525,108.095520 577.573486,108.086403 
	C595.891541,108.018890 614.215881,107.778198 632.525513,108.177162 
	C640.404968,108.348854 648.482666,109.039192 656.068604,111.026810 
	C670.784607,114.882607 675.993164,131.453644 673.469116,142.935242 
	C672.759155,146.164810 671.624207,149.300919 669.961182,152.735321 
	C651.149902,152.974487 633.060974,152.886963 614.974548,153.061249 
	C613.316711,153.077225 610.515381,154.484619 610.213196,155.725220 
	C608.179138,164.077454 606.676941,172.559204 605.002441,180.999008 
z"
        />
        <path
          fill="#374447"
          opacity="1.000000"
          stroke="none"
          d="
M605.470459,181.022507 
	C606.676941,172.559204 608.179138,164.077454 610.213196,155.725220 
	C610.515381,154.484619 613.316711,153.077225 614.974548,153.061249 
	C633.060974,152.886963 651.149902,152.974487 669.728394,153.005341 
	C665.328918,164.141968 657.463562,172.498383 646.267334,177.176346 
	C641.459656,179.185104 636.112488,180.478394 630.919800,180.858307 
	C622.632446,181.464630 614.269409,181.036652 605.470459,181.022507 
z"
        />
        <path
          fill="#6C554D"
          opacity="1.000000"
          stroke="none"
          d="
M764.164307,46.009239 
	C764.274475,45.878857 764.548523,45.977375 764.601746,46.111267 
	C764.454712,46.209984 764.254456,46.174801 764.164307,46.009239 
z"
        />
        <path
          fill="#6C554D"
          opacity="1.000000"
          stroke="none"
          d="
M446.719910,49.755375 
	C443.864227,54.538742 440.588745,58.859482 437.926208,63.529678 
	C424.099701,87.782295 410.380585,112.096603 396.717651,136.441940 
	C380.922974,164.585724 365.209900,192.775391 349.491699,220.962021 
	C339.377319,239.099609 329.340363,257.280396 319.226379,275.418213 
	C309.927704,292.093872 300.586121,308.745575 291.239014,325.394135 
	C276.266388,352.062500 261.260406,378.712097 246.294769,405.384399 
	C238.258209,419.707458 230.154053,433.995087 222.339935,448.439117 
	C219.801682,453.130981 216.928528,455.241516 211.132614,455.173920 
	C179.485489,454.804688 147.831833,454.988464 116.180435,455.012238 
	C114.366943,455.013580 112.553650,455.280579 110.406128,455.659088 
	C95.516716,441.424286 80.961449,426.954407 66.233994,412.200775 
	C68.155205,411.610229 70.248100,411.040070 72.342064,411.036133 
	C103.992210,410.976440 135.645508,410.765747 167.290039,411.189819 
	C173.196640,411.269012 176.131836,408.889252 178.585114,404.449890 
	C188.076599,387.274384 197.503998,370.063080 207.071915,352.930267 
	C218.417877,332.613525 229.937653,312.393921 241.283203,292.076996 
	C252.778793,271.491364 264.077637,250.795685 275.600189,230.225250 
	C290.868896,202.967087 306.334595,175.819244 321.596100,148.557053 
	C334.103149,126.215286 346.342926,103.723778 358.870331,81.393555 
	C372.135620,57.748062 385.606964,34.218208 398.963531,10.623799 
	C399.775391,9.189673 400.368896,7.631949 401.119141,5.729699 
	C402.594513,6.392760 404.162262,7.308806 405.412384,8.545796 
	C419.164398,22.153063 432.866913,35.810287 446.719910,49.755375 
z"
        />
        <path
          fill="#6C554D"
          opacity="1.000000"
          stroke="none"
          d="
M112.104828,7.022109 
	C125.021629,19.323456 137.893692,32.082870 150.762772,44.845264 
	C152.170959,46.241779 153.562103,47.655472 155.207062,49.618004 
	C155.733917,50.443882 156.014877,50.712952 156.295837,50.982018 
	C156.295837,50.982014 156.118118,50.853554 156.101776,51.231483 
	C157.408569,61.303219 158.651917,71.008728 160.071106,80.688454 
	C163.272766,102.525795 166.547913,124.352402 169.820084,146.179337 
	C171.544876,157.684509 173.179626,169.206146 175.131638,180.673279 
	C176.519821,188.828140 178.369858,196.904358 180.013763,205.015686 
	C170.534546,222.107162 161.055328,239.198654 151.576111,256.290131 
	C151.113785,256.215546 150.651474,256.140991 150.189163,256.066406 
	C148.790161,246.999405 147.424988,237.927048 145.985458,228.866501 
	C142.470535,206.743011 138.849731,184.636124 135.402847,162.502090 
	C130.499710,131.016846 125.713829,99.513336 120.878510,68.017525 
	C118.035065,49.496136 115.181328,30.976307 112.370178,12.450021 
	C112.122177,10.815597 112.156181,9.138382 112.104828,7.022109 
z"
        />
        <path
          fill="#384447"
          opacity="1.000000"
          stroke="none"
          d="
M180.252655,204.773468 
	C178.369858,196.904358 176.519821,188.828140 175.131638,180.673279 
	C173.179626,169.206146 171.544876,157.684509 169.820084,146.179337 
	C166.547913,124.352402 163.272766,102.525795 160.071106,80.688454 
	C158.651917,71.008728 157.408569,61.303219 156.169556,51.145302 
	C169.801636,64.136757 183.383057,77.559082 196.838043,91.106964 
	C197.994888,92.271790 198.690567,94.207893 198.963684,95.889160 
	C202.051270,114.895325 205.014389,133.921722 208.043991,153.334702 
	C205.188873,159.502380 202.483231,165.378464 199.373810,171.032501 
	C193.197388,182.263474 186.798508,193.372086 180.252655,204.773468 
z"
        />
        <path
          fill="#53514A"
          opacity="1.000000"
          stroke="none"
          d="
M814.974060,103.675049 
	C811.645508,98.597076 808.346741,93.162689 805.029236,87.357262 
	C809.010986,91.073318 813.011536,95.160416 817.077148,99.589752 
	C816.429443,101.060875 815.716675,102.189751 814.974060,103.675049 
z"
        />
        <path
          fill="#384447"
          opacity="1.000000"
          stroke="none"
          d="
M156.217728,50.672417 
	C156.014877,50.712952 155.733917,50.443882 155.283081,49.938919 
	C155.455338,49.922947 155.797485,50.142879 156.217728,50.672417 
z"
        />
      </svg> */}
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
