import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import NotistackProvider from './components/NotistackProvider';
import { ProgressBarStyle } from './components/ProgressBar';
import ScrollToTop from './components/ScrollToTop';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import { ChartStyle } from './components/chart';
import AuthModal from './components/modals/AuthModal/AuthModal';
import CreditInfo from './components/modals/CreditsModal/CreditInfo';
import ThemeSettings from './components/settings';
import BannerContainer from './components/modals/Banner/BannerContainer';


const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;

const CLARITY_PROJECT_ID = process.env.REACT_APP_CLARITY_PROJECT_ID;

if (GA_MEASUREMENT_ID) {
  ReactGA.initialize(GA_MEASUREMENT_ID);
} else {
  console.error('GA_MEASUREMENT_ID is missing');
}
// ReactGA.initialize(GA_MEASUREMENT_ID);

// ----------------------------------------------------------------------

export default function App() {
  const authModal = useSelector((state) => state.authModal);
  const isCreditModalOpen = useSelector((state) => state.credits.isCreditModalOpen);

  useEffect(() => {
    if (GA_MEASUREMENT_ID) {
      ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
    }
    // Initialize Google Analytics
    // ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });

  }, []);

  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ThemeSettings>
          <NotistackProvider>
          <BannerContainer />
            <ProgressBarStyle />
            <ChartStyle />
            <ScrollToTop />
            <Router />
            {authModal.isOpen && <AuthModal />}
            {isCreditModalOpen && <CreditInfo />}
          </NotistackProvider>
        </ThemeSettings>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
