import {useState, useEffect} from 'react';
import { AppBar, Box, Button, Container, Stack, Toolbar } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { PATH_AUTH } from '../../routes/paths';
import useOffSetTop from '../../hooks/useOffSetTop';
import useResponsive from '../../hooks/useResponsive';
import { openModal } from '../../redux/slices/authModal';
import Iconify from '../../components/Iconify';
import { IconButtonAnimate } from '../../components/animate';
import Logo from '../../components/Logo';
import { HEADER } from '../../config';
import cssStyles from '../../utils/cssStyles';
import navConfig from './MenuConfig';
import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';


const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: HEADER.MOBILE_HEIGHT,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('md')]: {
    height: HEADER.MAIN_DESKTOP_HEIGHT,
  },
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8,
}));


export default function MainHeader() {
  const isOffset = useOffSetTop(HEADER.MAIN_DESKTOP_HEIGHT);
  const dispatch = useDispatch();
  const theme = useTheme();
  const { pathname } = useLocation();
  const navigate = useNavigate()

  const isDesktop = useResponsive('up', 'md');
  const isHome = false;

  const listingArray = ReturnListingArray();

  const finalConfig =  navConfig;

  const [open, setOpen] = useState(false);

  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    if (drawerOpen) {
      handleDrawerClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <AppBar sx={{ boxShadow: 0, bgcolor: 'transparent' }}>
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            ...cssStyles(theme).bgBlur(),
            height: { md: HEADER.MAIN_DESKTOP_HEIGHT - 16 },
          }),
        }}
      >
        <Container
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {isDesktop && <Logo sx={{ mr: 2.5 }} />}

          {!isDesktop && (
           <IconButtonAnimate
           onClick={handleDrawerOpen}
           sx={{
             ml: 1,
             ...(isHome && { color: 'common.white' }),
             ...(isOffset && { color: 'text.primary' }),
           }}
         >
           <Iconify icon={'eva:menu-2-fill'} />
         </IconButtonAnimate>
          )}

          <Box sx={{ flexGrow: 1 }} />

          {isDesktop && <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={finalConfig} />}

          {!isDesktop && <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={finalConfig} drawerOpen={drawerOpen} handleDrawerClose={handleDrawerClose}/>}


          <Stack direction="row" spacing={2}>
            <Button
              variant="text"
              onClick={() => dispatch(openModal('login'))}
            >
              Login
            </Button>
            <Button
              variant="contained"
              // onClick={() => dispatch(openModal('signup'))}
              onClick={() => navigate(PATH_AUTH.signUp)}
            >
              Sign Up
            </Button>
          </Stack>

        </Container>
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  );
}

const ReturnListingArray = () => {
  const { pathname } = useLocation();

  if (pathname === '/') {
    return [
      {
        title: 'University Listings',
        path: '/university/listings',
      },
    ];
  }
  if (pathname === '/university/listings') {
    return [
      {
        title: 'Recruiter Listings',
        path: '/',
      },
    ];
  }
  // /directory
  return [
    {
      title: 'Listings',
      children: [
        {
          title: 'University Listings',
          path: '/university/listings',
        },
        {
          title: 'Recruiter Listings',
          path: '/',
        },
      ],
    },
  ];
};
