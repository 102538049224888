import { Dialog, DialogContent, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '../../../redux/slices/authModal';
import ForgotPassMode from './ForgotPassMode';
import SignInMode from './SignInMode';
import SignUpMode from './SignUpMode';
import VerifyCodeMode from './VerifyCodeMode';
import VerifyOTP from './verify-code/VerifyOTP'


export default function AuthModal() {
    const dispatch = useDispatch();
    const { isOpen, mode } = useSelector((state) => state.authModal);

    return (
        <Dialog open={isOpen} onClose={() => dispatch(closeModal())} maxWidth="md" width="80%" >
            <DialogContent>
                <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
                    {mode === 'login' && 'Login'}
                    {mode === 'signup' && 'Sign Up'}
                </Typography>
                {mode === 'login' && <SignInMode />}
                {mode === 'signup' && <SignUpMode />}
                {mode === 'forgotPassword' && <ForgotPassMode />}
                {mode === 'verifyCode' && <VerifyOTP />}
            </DialogContent>
        </Dialog>
    );
}
