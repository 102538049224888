import React, { useState, useEffect } from 'react'
import { Card, Typography, CircularProgress, Tooltip } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { styled, keyframes } from '@mui/system'
import useAuth from '../../../hooks/useAuth'
import { setTotalCredits, openModal } from '../../../redux/slices/credits'

const pulse = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
  }
`;

const ClickableCard = styled(Card)(({ theme, lowCredits }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  border: lowCredits ? '2px solid #ff0000' : '1px solid #3b7299',
  backgroundColor: lowCredits ? '#fff0f0' : theme.palette.background.paper,
  cursor: 'pointer',
  transition: 'all 0.3s',
  animation: lowCredits ? `${pulse} 2s infinite` : 'none',
  '&:hover': {
    backgroundColor: lowCredits ? '#ffe0e0' : theme.palette.action.hover,
    transform: 'scale(1.05)',
  },
}));

const CreditsIndicator = () => {
  const { user } = useAuth()
  const { totalCredits } = useSelector((state) => state.credits);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(setTotalCredits(user.credits))
  }, [user, dispatch])

  const handleClick = async () => {
    setIsLoading(true);
    dispatch(openModal());
    // Simulate waiting for modal to open
    await new Promise(resolve => setTimeout(resolve, 1000));
    setIsLoading(false);
  };

  const getLowCreditsMessage = () => {
    if (totalCredits === 0) {
      return "You're out of credits! Click to add more and continue using our services.";
    }
    if (totalCredits < 5) {
      return "Low credits! Click to add more and avoid interruptions.";
    }
    return "Click to manage your credits";
  };

  const isLowCredits = totalCredits < 5;

  return (
    <Tooltip title={getLowCreditsMessage()} arrow>
      <ClickableCard onClick={handleClick} lowCredits={isLowCredits}>
        <Typography variant="body2" sx={{ mr: 1, color: isLowCredits ? 'error.main' : 'inherit' }}>
          Credits:
        </Typography>
        {isLoading ? (
          <CircularProgress size={20} thickness={4} />
        ) : (
          <Typography 
            variant="body1" 
            sx={{ 
              fontWeight: 'bold', 
              color: isLowCredits ? 'error.main' : 'inherit',
              animation: isLowCredits ? 'blink 1s linear infinite' : 'none',
              '@keyframes blink': {
                '0%': { opacity: 1 },
                '50%': { opacity: 0.5 },
                '100%': { opacity: 1 },
              },
            }}
          >
            {totalCredits}
          </Typography>
        )}
      </ClickableCard>
    </Tooltip>
  )
}

export default CreditsIndicator