import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Banner from './Banner';
import axios from '../../../utils/axios';
import { AuthContext } from '../../../contexts/JWTContext';
import AccountPendingBanner from './AccountPendingBanner';
import AccountLockedBanner from './AccountLockedBanner';
import VerifyOTP from '../AuthModal/verify-code/VerifyOTP';
import { openModal } from '../../../redux/slices/authModal';




const fetchActiveBanners = async () => {
    try {
        const response = await axios.get('api/v1/auxiliary/active-banners/');
        return response.data;
    } catch (error) {
        console.error('Error fetching banners:', error);
        return [];
    }
};

const dismissBanner = async (bannerId) => {
    try {
        await axios.post(`/api/v1/auxiliary/dismiss-banner/${bannerId}/`);
    } catch (error) {
        console.error('Error dismissing banner:', error);
    }
};

const BannerContainer = () => {
  const [banners, setBanners] = useState([]);
  const { isAuthenticated, user, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/");

    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const getBanners = async () => {
      if (isAuthenticated && user) {
        const lastBannerFetchTime = localStorage.getItem('lastBannerFetchTime');
        const lastLoginTime = localStorage.getItem('lastLoginTime');

        if (!lastBannerFetchTime || (lastLoginTime && new Date(lastLoginTime) > new Date(lastBannerFetchTime))) {
          try {
            const fetchedBanners = await fetchActiveBanners();
            setBanners(fetchedBanners);
            localStorage.setItem('lastBannerFetchTime', new Date().toISOString());
          } catch (error) {
            console.error('Error fetching banners:', error);
          }
        }
      }
    };

    getBanners();
  }, [isAuthenticated, user]);

  const handleDismiss = async (bannerId) => {
    try {
      await dismissBanner(bannerId);
      setBanners(banners.filter(banner => banner.id !== bannerId));
    } catch (error) {
      console.error('Error dismissing banner:', error);
    }
  };

  useEffect(() => {
    console.log(user)
    if (user && user.isOTPVerified === false) {
      dispatch(openModal('verifyCode'))
      
    }
  }
  , [user])

  return (
    <>


    {user && user.verificationStatus === 'pending' && user.isOTPVerified && (
      <AccountPendingBanner user={user} logout ={handleLogout}/>

    )}

{user && user.verificationStatus === 'banned' && user.isOTPVerified && (

      <AccountLockedBanner user={user} logout ={handleLogout}/>

    )}
      {banners.map(banner => (
        <Banner key={banner.id} banner={banner} onDismiss={handleDismiss}  />
      ))}
    </>
  );
};

export default BannerContainer;