import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import { closeModal, setMode } from '../../../redux/slices/authModal';
import axios from '../../../utils/axios';
import { setSession } from '../../../utils/jwt';
import Iconify from '../../Iconify';
import { FormProvider, RHFTextField } from '../../hook-form';

const positions = {
  University: [
    { value: 'tpo', label: 'TPO' },
    { value: 'dean', label: 'Dean' },
    { value: 'studentbody', label: 'Student Body' },
    { value: 'professor', label: 'Professor' },
    { value: 'student', label: 'Student' },
    { value: 'other', label: 'Other' },
  ],
  Company: [
    { value: 'hr', label: 'HR' },
    { value: 'ceo', label: 'CEO/MD/Founder' },
    { value: 'hiringmanager', label: 'Hiring Manager' },
    { value: 'other', label: 'Other' },
  ],
};

export default function SignUpMode() {
  const dispatch = useDispatch();
  const isMountedRef = useIsMountedRef();
  const [showPassword, setShowPassword] = useState(false);
  const [userType, setUserType] = useState('');
  const [userPosition, setUserPosition] = useState('');

  const { enqueueSnackbar } = useSnackbar();
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string().required('First name required'),
    lastName: Yup.string().required('Last name required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
    userType: Yup.string().required('Select user type'),
    position: Yup.string().required('Select a position'),
    universityName: Yup.string().when('userType', {
      is: 'University',
      then: Yup.string().required('Name of University is required'),
    }),
    companyName: Yup.string().when('userType', {
      is: 'Company',
      then: Yup.string().required('Name of Company is required'),
    }),
    mobile: Yup.string()
    .matches(/^[0-9]+$/, "Mobile number must be only digits")
    .length(10, "Mobile number must be 10 digits")
    .required("Mobile number is required")
  });

  const defaultValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    mobile: '',
    universityName: '',
    companyName: '',
    position: '',
    userType: '',
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      let endpoint;
      let sendData;
      if (data.userType === 'University') {
        sendData = {
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          password: data.password,
          mobile: data.mobile,
          universityName: data.universityName,
          position: data.position,
        };
        endpoint = '/api/v1/accounts/register/university/';
      } else if (data.userType === 'Company') {
        sendData = {
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          password: data.password,
          mobile: data.mobile,
          companyName: data.companyName,
          position: data.position,
        };
        endpoint = '/api/v1/accounts/register/company/';
      }
      const response = await axios.post(endpoint, sendData);
      const accessToken = response.data.accessToken;
      setSession(accessToken);

      if (response.status === 200) {
        reset();
        dispatch(closeModal());
        window.location.reload();
      }
    } catch (error) {
      console.error('Error:', error);
      enqueueSnackbar(error.message, { variant: 'error' });
      reset();
      if (isMountedRef.current) {
        setError('afterSubmit', { ...error, message: error.message });
      }
    }
  };

  const handleUserType = (event) => {
    setUserType(event.target.value);
    methods.setValue('userType', event.target.value);
  };

  const handleUserPosition = (value) => {
    setUserPosition(value);
    methods.setValue('position', value);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <RHFTextField name="firstName" label="First name" />
          <RHFTextField name="lastName" label="Last name" />
        </Stack>
        <RHFTextField name="email" label="Email address" />
        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <RHFTextField name="mobile" label="Mobile Number" />
        <Controller
          name="userType"
          control={methods.control}
          render={({ field }) => (
            <FormControl fullWidth error={!!errors.userType}>
              <InputLabel>Organization Type</InputLabel>
              <Select {...field} label="Category" onChange={handleUserType} value={userType}>
                <MenuItem value="University">University</MenuItem>
                <MenuItem value="Company">Company</MenuItem>
              </Select>
              {errors.userType && <Typography color="error">{errors.userType.message}</Typography>}
            </FormControl>
          )}
        />
        {userType === 'University' && <RHFTextField name="universityName" label="University Name" />}
        {userType === 'Company' && <RHFTextField name="companyName" label="Company Name" />}
        {userType && (
          <Controller
            name="position"
            control={methods.control}
            render={({ field }) => (
              <FormControl fullWidth error={!!errors.position}>
                <InputLabel>Position</InputLabel>
                <Select
                  {...field}
                  value={userPosition}
                  label="Position"
                  onChange={(event) => handleUserPosition(event.target.value)}
                >
                  {positions[userType]?.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                {errors.position && <Typography color="error">{errors.position.message}</Typography>}
              </FormControl>
            )}
          />
        )}

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Register
        </LoadingButton>

        <Typography variant="body2" align="center" sx={{ color: 'text.secondary', mt: 3 }}>
          By registering, you agree to PlacementConnect's &nbsp;
          <Link underline="always" color="text.primary" href="https://placementconnect.in/about/tnc">
            Terms of Service
          </Link>
          {''} and {''}
          <Link underline="always" color="text.primary" href="https://placementconnect.in/about/privacy-policy">
            Privacy Policy
          </Link>
          .
        </Typography>

        <Button onClick={() => dispatch(setMode('login'))}>Already have an account? Login</Button>
      </Stack>
    </FormProvider>
  );
}