import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Grid, Link, Divider, Container, Typography, Stack } from '@mui/material';
// routes
import { PATH_PAGE, PATH_TC } from '../../routes/paths';
// components
import Logo from '../../components/Logo';
import SocialsButton from '../../components/SocialsButton';

// ----------------------------------------------------------------------

const LINKS = [
  {
    headline: 'Solutions',
    children: [
      { name: 'Recruiter Directory', href: PATH_TC.directoryRecruiter },
      // { name: 'TPO Directory', href: PATH_TC.directoryTpo },
      { name: 'Recruiter Job Listings', href: PATH_TC.listing.recruiter },
      { name: 'TPO Listings', href: PATH_TC.listingUniversity },
    ],
  },
  {
    headline: 'About',
    children: [
      { name: 'Terms and Condition', href: PATH_TC.about.tnc },
      { name: 'Privacy Policy', href: PATH_TC.about.privacyPolicy },
      { name: 'Pricing', href: PATH_TC.about.pricing },
      {name : 'Refund Policy', href: PATH_TC.about.refundPolicy},
    ],
  },
];

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
  return (
    <RootStyle>
      <Divider />

      <Container sx={{ pt: 5 }}>
        <Grid
          container
          justifyContent={{ xs: 'center', md: 'space-between' }}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          

          <Grid item xs={8} md={3}>
          <Grid item xs={12} sx={{ mb: 3 }}>
            <Logo sx={{ mx: { xs: 'auto', md: 'inherit' } }} />
          </Grid>
            <Typography variant="body2" sx={{ pr: { md: 5 } }}>
            Placement Connect: Bridging TPO Departments and recruiters to seamlessly connect university talent with job placements.
            </Typography>
          </Grid>

          <Grid item xs={12} md={7}>
            <Stack spacing={5} direction={{ xs: 'column', md: 'row' }} justifyContent="space-between">
              {LINKS.map((list) => (
                <Stack key={list.headline} spacing={2}>
                  <Typography component="p" variant="overline">
                    {list.headline}
                  </Typography>

                  {list.children.map((link) => (
                    <Link
                      to={link.href}
                      key={link.name}
                      color="inherit"
                      variant="body2"
                      component={RouterLink}
                      sx={{ display: 'block' }}
                    >
                      {link.name}
                    </Link>
                  ))}
                </Stack>
              ))}
              <Stack spacing={2}>
                  <Typography component="p" variant="overline">
                    Contact
                  </Typography>

                  <Typography  color="inherit" variant="body2" sx={{ display: 'block' }}>
                    support@placementconnect.in
                  </Typography>
                  <Typography  color="inherit" variant="body2" sx={{ display: 'block' }}>
                    +91 7428 1090 69
                  </Typography>
                  <Link
                      to={PATH_TC.about.aboutUs}
                      color="inherit"
                      variant="body2"
                      component={RouterLink}
                      sx={{ display: 'block' }}
                    >
                      About Us
                    </Link>
                    <Link
                      to={PATH_TC.about.contactUs}
                      color="inherit"
                      variant="body2"
                      component={RouterLink}
                      sx={{ display: 'block' }}
                    >
                      Contact Us
                    </Link>
                </Stack>
            </Stack>
          </Grid>
        </Grid>

        <Typography
          component="p"
          variant="body2"
          sx={{
            mt: 10,
            pb: 5,
            fontSize: 13,
            textAlign: { xs: 'center', md: 'left' },
          }}
        >
          Placement Connect © 2024. All rights reserved. 
        </Typography>
      </Container>
    </RootStyle>
  );
}
