import { createSlice } from '@reduxjs/toolkit';
import { add, set } from 'lodash';


const initialState = {
    totalCredits: null,
    isCreditModalOpen: false
};

const creditModalSlice = createSlice({
    name: 'creditModal',
    initialState,
    reducers: {
        openModal(state, action) {
            state.isCreditModalOpen = true;
        },
        closeModal(state) {
            state.isCreditModalOpen = false;
        },
        setTotalCredits(state, action) {
            state.totalCredits = action.payload;
        },
        addCredits(state, action) {
            state.totalCredits += action.payload;
        },
        deductCredits(state, action) {
            state.totalCredits -= action.payload;
        },

    },
});

export const { openModal, closeModal, setTotalCredits, addCredits, deductCredits } = creditModalSlice.actions;

export default creditModalSlice.reducer;
