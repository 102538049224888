import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
import { PATH_AUTH } from '../../../routes/paths';
// components
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import axios from '../../../utils/axios';
import ResetPasswordDailog from './ResetPasswordDailog';

// ----------------------------------------------------------------------

export default function ResetPasswordForm({spEmail}) {
  const navigate = useNavigate();
  const [dailogOpen, setDailogOpen] = useState(false)
  const [dailogMessage, setDailogMessage] = useState("")

  const handleDailogClose = () => {
    setDailogOpen(false);
    navigate("/")
  };

  const ResetPasswordSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
  });

  const defaultValues = useMemo(
    () => ({
      email: spEmail || ''
    })
  )

  const methods = useForm({
    resolver: yupResolver(ResetPasswordSchema),
    defaultValues
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      console.log(data)
      const response = await axios.post(`/api/v1/accounts/email-reset-password/`, data);
      console.log(response)
      const {error: ResponseError, message: ResponseMessage } = response.data;
      console.log(ResponseMessage)
      if (ResponseError) {
        setDailogMessage(ResponseError)
      setDailogOpen(true)
      }
      setDailogMessage(ResponseMessage)
      setDailogOpen(true)

    } catch (error) {
      console.log(error)
      if (error.message === "{'error': [ErrorDetail(string='Account not found for the given email ID', code='invalid')]}") {
        setDailogMessage("Account not found for the given email ID")
        setDailogOpen(true)
      } else {
        setDailogMessage("There was an error while trying to reset your password. Please contact support if this persists.")
        setDailogOpen(true)
      }
      
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFTextField name="email" label="Email address" />

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Send Request
        </LoadingButton>
        <ResetPasswordDailog open={dailogOpen} onClose={handleDailogClose} message={dailogMessage}/>
      </Stack>
    </FormProvider>
    
  );
}
