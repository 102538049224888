import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isOpen: false,
    mode: 'login', // 'login' | 'signup' | 'forgotPassword' | 'verifyCode'
};

const authModalSlice = createSlice({
    name: 'authModal',
    initialState,
    reducers: {
        openModal(state, action) {
            state.isOpen = true;
            state.mode = action.payload;
        },
        closeModal(state) {
            state.isOpen = false;
        },
        setMode(state, action) {
            state.mode = action.payload;
        },
    },
});

export const { openModal, closeModal, setMode } = authModalSlice.actions;

export default authModalSlice.reducer;
