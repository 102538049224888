import React from 'react';
import { Dialog, DialogContent, IconButton, Box } from '@mui/material';
import DOMPurify from 'dompurify';
import Iconify from '../../Iconify';

const Banner = ({ banner, onDismiss }) => {
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
    onDismiss(banner.id);
  };

  const handleClick = () => {
    handleClose();
    if (banner.content.link_url) {
      window.open(banner.content.link_url, '_blank', 'noopener,noreferrer');
    }
  };

  // Sanitize the HTML content
  const sanitizedContent = DOMPurify.sanitize(banner?.content?.html_content);

  return (
    <Dialog 
      open={open} 
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          overflow: 'visible'
        }
      }}
    >
      <DialogContent 
        style={{ padding: 0, position: 'relative', cursor: 'pointer' }}
        onClick={handleClick}
      >
        <Box sx={{
          position: 'relative',
          overflow: 'hidden',
          borderRadius: '10px',
        }}>
          {/* Render the sanitized HTML content */}
          <div dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
          
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              handleClose();
            }}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
              },
            }}
          >
            <Iconify icon="mdi:close" width={24} height={24} />
          </IconButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default Banner;