import React from 'react';
import { Button, Stack, Typography, Alert } from '@mui/material';
import { useDispatch } from 'react-redux';
import { ResetPasswordForm } from '../../../sections/auth/reset-password';
import { setMode } from '../../../redux/slices/authModal';

export default function ForgotPassMode() {
    const dispatch = useDispatch();

    return (
        <Stack spacing={3}>
            {/* <Alert severity="warning" sx={{ mb: 2 }}>
                <Typography variant="body1">
                    We are currently experiencing disruptions with our email service. Users may not receive password reset emails at this time. We are actively working to resolve this issue. Last updated: 23/08/24 4:00 PM. Please contact support to reset your password. We apologize for any inconvenience.
                </Typography>
            </Alert> */}

            <Typography variant="h3" paragraph>
                Forgot your password?
            </Typography>

            <Typography sx={{ color: 'text.secondary', mb: 5 }}>
                Please enter the email address associated with your account. We will email you a link to reset your password once our email service is restored.
            </Typography>

            <ResetPasswordForm />

            <Button onClick={() => dispatch(setMode('login'))}>
                Back
            </Button>
        </Stack>
    );
}