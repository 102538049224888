import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

LogoLogin.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function LogoLogin({ disabledLink = false, sx }) {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  // OR
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="100%"
        viewBox="0 0 278 203"
        enableBackground="new 0 0 278 203"
        xmlSpace="preserve"
      >
        <path
          fill="#FFFFFF"
          opacity="1.000000"
          stroke="none"
          d="
M172.000000,204.000000 
	C114.666672,204.000000 57.833347,204.000000 1.000015,204.000000 
	C1.000010,136.333344 1.000010,68.666695 1.000005,1.000033 
	C93.666634,1.000022 186.333267,1.000022 278.999939,1.000011 
	C278.999939,68.666634 278.999939,136.333267 278.999969,203.999939 
	C243.500000,204.000000 208.000000,204.000000 172.000000,204.000000 
M190.243958,28.633917 
	C182.395599,30.237995 176.031921,34.512604 169.579590,40.921551 
	C169.579590,35.948441 169.579590,32.086189 169.579590,28.282490 
	C159.851929,28.282490 150.761887,28.282490 141.614349,28.282490 
	C141.614349,80.268097 141.614349,131.970444 141.614349,183.681335 
	C151.007095,183.681335 160.073868,183.681335 169.596359,183.681335 
	C169.596359,165.350754 169.596359,147.318222 169.596359,128.424210 
	C170.896530,129.467575 171.519699,129.921417 172.091202,130.432999 
	C187.156067,143.918655 204.547760,145.111343 222.362228,138.242523 
	C258.586700,124.275276 265.968536,75.049927 243.543900,46.336006 
	C230.216919,29.271305 211.970383,24.324133 190.243958,28.633917 
M59.102207,68.311165 
	C55.014694,54.900631 50.927181,41.490097 46.873299,28.189888 
	C36.921509,28.189888 27.468924,28.189888 17.887550,28.189888 
	C17.952465,29.034451 17.882467,29.724413 18.070047,30.335283 
	C29.056763,66.114326 40.032906,101.896782 51.199432,137.619705 
	C51.602219,138.908264 53.888821,140.428574 55.317142,140.448761 
	C69.306259,140.646454 83.300285,140.469849 97.291084,140.598312 
	C100.173866,140.624771 101.220657,139.560455 102.032494,136.899292 
	C111.212685,106.806992 120.606995,76.779945 129.760925,46.679764 
	C131.547714,40.804413 132.550629,34.690674 134.067825,28.014919 
	C125.124290,28.014919 117.658913,28.207611 110.211380,27.926888 
	C106.899734,27.802059 105.724510,28.986485 104.800407,32.038982 
	C97.078651,57.545296 89.008873,82.947197 81.434494,108.496353 
	C79.985405,113.384254 77.893463,115.078720 73.103279,113.616470 
	C68.525574,98.800133 63.934166,83.939445 59.102207,68.311165 
z"
        />
        <path
          fill="#0F0F04"
          opacity="1.000000"
          stroke="none"
          d="
M190.637039,28.492266 
	C211.970383,24.324133 230.216919,29.271305 243.543900,46.336006 
	C265.968536,75.049927 258.586700,124.275276 222.362228,138.242523 
	C204.547760,145.111343 187.156067,143.918655 172.091202,130.432999 
	C171.519699,129.921417 170.896530,129.467575 169.596359,128.424210 
	C169.596359,147.318222 169.596359,165.350754 169.596359,183.681335 
	C160.073868,183.681335 151.007095,183.681335 141.614349,183.681335 
	C141.614349,131.970444 141.614349,80.268097 141.614349,28.282490 
	C150.761887,28.282490 159.851929,28.282490 169.579590,28.282490 
	C169.579590,32.086189 169.579590,35.948441 169.579590,40.921551 
	C176.031921,34.512604 182.395599,30.237995 190.637039,28.492266 
M169.698624,75.705650 
	C169.781799,82.182632 169.552780,88.682297 170.021072,95.131310 
	C170.730179,104.896851 177.070404,111.970787 186.771103,113.133743 
	C193.978394,113.997772 201.355927,114.003418 208.621567,113.649925 
	C215.005875,113.339310 220.508530,110.257584 224.086517,104.860771 
	C232.217499,92.596474 232.073410,79.662041 226.114822,66.631996 
	C222.840912,59.472725 216.938873,55.107109 209.174057,54.409973 
	C202.259430,53.789169 195.161682,53.664162 188.286362,54.493843 
	C176.584564,55.905972 170.740356,62.796978 169.698624,75.705650 
z"
        />
        <path
          fill="#0E0E03"
          opacity="1.000000"
          stroke="none"
          d="
M59.222481,68.694962 
	C63.934166,83.939445 68.525574,98.800133 73.103279,113.616470 
	C77.893463,115.078720 79.985405,113.384254 81.434494,108.496353 
	C89.008873,82.947197 97.078651,57.545296 104.800407,32.038982 
	C105.724510,28.986485 106.899734,27.802059 110.211380,27.926888 
	C117.658913,28.207611 125.124290,28.014919 134.067825,28.014919 
	C132.550629,34.690674 131.547714,40.804413 129.760925,46.679764 
	C120.606995,76.779945 111.212685,106.806992 102.032494,136.899292 
	C101.220657,139.560455 100.173866,140.624771 97.291084,140.598312 
	C83.300285,140.469849 69.306259,140.646454 55.317142,140.448761 
	C53.888821,140.428574 51.602219,138.908264 51.199432,137.619705 
	C40.032906,101.896782 29.056763,66.114326 18.070047,30.335283 
	C17.882467,29.724413 17.952465,29.034451 17.887550,28.189888 
	C27.468924,28.189888 36.921509,28.189888 46.873299,28.189888 
	C50.927181,41.490097 55.014694,54.900631 59.222481,68.694962 
z"
        />
        <path
          fill="#FEFEFE"
          opacity="1.000000"
          stroke="none"
          d="
M169.735123,75.237381 
	C170.740356,62.796978 176.584564,55.905972 188.286362,54.493843 
	C195.161682,53.664162 202.259430,53.789169 209.174057,54.409973 
	C216.938873,55.107109 222.840912,59.472725 226.114822,66.631996 
	C232.073410,79.662041 232.217499,92.596474 224.086517,104.860771 
	C220.508530,110.257584 215.005875,113.339310 208.621567,113.649925 
	C201.355927,114.003418 193.978394,113.997772 186.771103,113.133743 
	C177.070404,111.970787 170.730179,104.896851 170.021072,95.131310 
	C169.552780,88.682297 169.781799,82.182632 169.735123,75.237381 
z"
        />
      </svg>
      {/* <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="100%"
        viewBox="0 0 871 498"
        enableBackground="new 0 0 871 498"
        xmlSpace="preserve"
      >
        <path
          fill="#9D6A57"
          opacity="1.000000"
          stroke="none"
          d="
M480.000000,1.000001 
	C547.354248,1.000000 614.708435,1.000000 682.707031,1.361344 
	C716.324219,5.448396 742.687744,21.286448 764.164307,46.009239 
	C764.254456,46.174801 764.454712,46.209984 764.697327,46.280235 
	C764.739685,46.315311 764.725281,46.424370 764.576538,46.793419 
	C765.169800,48.783638 765.733521,50.518215 766.681091,52.008678 
	C777.863098,69.596642 782.908264,89.046448 783.947815,109.628075 
	C785.023132,130.916580 780.845276,151.277664 772.925354,171.035828 
	C764.213562,192.769562 752.094788,212.355835 735.994873,229.239182 
	C724.184570,241.624100 711.056274,252.502533 696.367981,261.658813 
	C668.704041,278.903778 638.904907,288.474091 606.340515,288.929779 
	C582.848206,289.258514 559.344910,289.191559 535.852844,288.856110 
	C530.373169,288.777893 528.211914,290.561798 527.201965,295.745972 
	C523.736145,313.536560 519.958374,331.267120 516.210571,349.001770 
	C512.633911,365.926361 508.924988,382.822968 505.316772,399.740967 
	C503.771210,406.987549 503.820221,406.993561 496.544342,406.993530 
	C465.048523,406.993439 433.552643,406.973907 402.057007,407.048676 
	C399.994385,407.053558 397.933472,407.788300 395.516083,407.962433 
	C395.234161,405.954102 395.062927,404.116333 395.420013,402.387634 
	C398.571320,387.132599 401.819427,371.897583 405.033417,356.655457 
	C415.607208,306.509888 426.215546,256.371552 436.718170,206.211075 
	C438.686066,196.812500 440.227722,187.324661 441.948914,177.563141 
	C442.674652,175.235428 443.672852,173.277695 444.120758,171.201248 
	C448.532440,150.748703 452.863068,130.278625 457.195374,109.809021 
	C460.117950,96.000221 463.006348,82.184189 465.951355,67.946518 
	C470.661804,45.347767 475.330902,23.173883 480.000000,1.000001 
M670.684143,152.479904 
	C671.624207,149.300919 672.759155,146.164810 673.469116,142.935242 
	C675.993164,131.453644 670.784607,114.882607 656.068604,111.026810 
	C648.482666,109.039192 640.404968,108.348854 632.525513,108.177162 
	C614.215881,107.778198 595.891541,108.018890 577.573486,108.086403 
	C575.100525,108.095520 572.461060,108.160927 570.213318,109.021751 
	C568.563660,109.653511 566.551575,111.434700 566.197083,113.035019 
	C561.560730,133.966324 557.308899,154.982880 552.659912,175.911240 
	C551.677979,180.331482 553.190796,181.103821 557.042786,181.075119 
	C573.028625,180.955917 589.015747,181.009094 605.938416,181.046005 
	C614.269409,181.036652 622.632446,181.464630 630.919800,180.858307 
	C636.112488,180.478394 641.459656,179.185104 646.267334,177.176346 
	C657.463562,172.498383 665.328918,164.141968 670.684143,152.479904 
z"
        />
        <path
          fill="#9D6A57"
          opacity="1.000000"
          stroke="none"
          d="
M1.000000,10.000000 
	C1.000000,8.611003 1.000000,7.222005 1.468648,5.419662 
	C37.075256,5.005901 72.213272,4.985011 107.351021,5.071142 
	C108.952034,5.075067 110.550690,6.043805 112.104828,7.022108 
	C112.156181,9.138382 112.122177,10.815597 112.370178,12.450021 
	C115.181328,30.976307 118.035065,49.496136 120.878510,68.017525 
	C125.713829,99.513336 130.499710,131.016846 135.402847,162.502090 
	C138.849731,184.636124 142.470535,206.743011 145.985458,228.866501 
	C147.424988,237.927048 148.790161,246.999405 150.189163,256.066406 
	C150.651474,256.140991 151.113785,256.215546 151.576111,256.290131 
	C161.055328,239.198654 170.534546,222.107162 180.252655,204.773468 
	C186.798508,193.372086 193.197388,182.263474 199.373810,171.032501 
	C202.483231,165.378464 205.188873,159.502380 208.393494,153.648071 
	C209.335602,152.589371 210.012024,151.637756 210.579712,150.625168 
	C237.027481,103.448166 263.498535,56.284119 289.828400,9.041391 
	C291.509155,6.025650 293.270569,4.896256 296.736450,4.913243 
	C326.397919,5.058620 356.060425,4.985820 385.722534,5.018627 
	C390.873230,5.024323 396.023712,5.219829 401.119141,5.729698 
	C400.368896,7.631949 399.775391,9.189673 398.963531,10.623799 
	C385.606964,34.218208 372.135620,57.748062 358.870331,81.393555 
	C346.342926,103.723778 334.103149,126.215286 321.596100,148.557053 
	C306.334595,175.819244 290.868896,202.967087 275.600189,230.225250 
	C264.077637,250.795685 252.778793,271.491364 241.283203,292.076996 
	C229.937653,312.393921 218.417877,332.613525 207.071915,352.930267 
	C197.503998,370.063080 188.076599,387.274384 178.585114,404.449890 
	C176.131836,408.889252 173.196640,411.269012 167.290039,411.189819 
	C135.645508,410.765747 103.992210,410.976440 72.342064,411.036133 
	C70.248100,411.040070 68.155205,411.610229 65.732674,411.716125 
	C65.055176,410.238953 64.581032,408.982452 64.376625,407.683502 
	C56.591461,358.212036 48.830898,308.736664 41.067406,259.261780 
	C35.266460,222.293732 29.488567,185.322083 23.659151,148.358536 
	C17.472923,109.132500 11.272973,69.908539 4.991576,30.697691 
	C3.880715,23.763271 2.341439,16.897482 1.000000,10.000000 
z"
        />
        <path
          fill="#FFFFFF"
          opacity="1.000000"
          stroke="none"
          d="
M441.966034,177.878021 
	C440.227722,187.324661 438.686066,196.812500 436.718170,206.211075 
	C426.215546,256.371552 415.607208,306.509888 405.033417,356.655457 
	C401.819427,371.897583 398.571320,387.132599 395.420013,402.387634 
	C395.062927,404.116333 395.234161,405.954102 395.665649,408.258057 
	C410.320526,422.896179 424.470154,437.017548 438.770020,451.449280 
	C452.387939,465.155365 465.776245,478.633240 479.421234,491.846008 
	C481.183899,493.552795 484.270599,494.718475 486.751801,494.732208 
	C520.245361,494.918152 553.740295,494.855438 587.234924,494.854309 
	C588.710388,494.854248 590.185852,494.854279 591.770996,494.854279 
	C600.156433,455.312805 608.466370,416.127441 616.795776,376.850128 
	C642.232544,376.850128 667.052002,376.898071 691.871155,376.833405 
	C708.623840,376.789734 725.071228,374.413239 741.086487,369.490082 
	C773.169373,359.627716 800.329468,341.891907 823.281799,317.555176 
	C843.988098,295.599915 858.702271,270.207855 866.715393,241.057739 
	C868.718323,233.771515 870.251160,226.356064 872.000000,219.000000 
	C872.000000,312.287384 872.000000,405.574799 872.000000,499.000000 
	C669.312439,499.000000 466.624878,499.000000 263.439209,498.738098 
	C262.921600,498.301636 262.902161,498.127014 263.075348,497.665710 
	C269.608124,486.066132 275.957458,474.758362 282.287018,463.439514 
	C335.515411,368.253479 388.740173,273.065430 441.966034,177.878021 
z"
        />
        <path
          fill="#374547"
          opacity="1.000000"
          stroke="none"
          d="
M872.000000,218.531342 
	C870.251160,226.356064 868.718323,233.771515 866.715393,241.057739 
	C858.702271,270.207855 843.988098,295.599915 823.281799,317.555176 
	C800.329468,341.891907 773.169373,359.627716 741.086487,369.490082 
	C725.071228,374.413239 708.623840,376.789734 691.871155,376.833405 
	C667.052002,376.898071 642.232544,376.850128 616.795776,376.850128 
	C608.466370,416.127441 600.156433,455.312805 591.770996,494.854279 
	C590.185852,494.854279 588.710388,494.854248 587.234924,494.854309 
	C553.740295,494.855438 520.245361,494.918152 486.751801,494.732208 
	C484.270599,494.718475 481.183899,493.552795 479.421234,491.846008 
	C465.776245,478.633240 452.387939,465.155365 439.260529,451.413208 
	C473.741669,451.040558 507.882660,450.951843 542.023010,451.065277 
	C546.410217,451.079834 548.520569,450.022308 549.328857,445.269379 
	C551.414185,433.007477 554.047241,420.834412 556.641113,408.664185 
	C561.526733,385.740875 566.558472,362.848755 571.486267,339.934387 
	C572.974792,333.012390 572.909424,332.998932 580.215210,332.997040 
	C605.030090,332.990662 629.859985,333.489807 654.653015,332.750610 
	C666.013306,332.411926 677.551270,330.609558 688.570374,327.769928 
	C712.759460,321.536255 734.780273,310.639282 754.787598,295.357147 
	C773.659058,280.942627 789.207886,263.774414 802.096313,243.982193 
	C812.847412,227.472290 819.987427,209.480545 824.380554,190.354111 
	C831.237732,160.499359 829.287048,131.581482 814.974060,103.675049 
	C815.716675,102.189751 816.429443,101.060875 817.482178,99.980362 
	C825.058350,107.090302 832.403137,114.044548 839.508911,121.235046 
	C856.139771,138.064148 866.418152,158.166122 870.207031,181.566422 
	C870.560669,183.750458 871.390991,185.857315 872.000000,188.000000 
	C872.000000,198.020889 872.000000,208.041794 872.000000,218.531342 
z"
        />
        <path
          fill="#FFFFFF"
          opacity="1.000000"
          stroke="none"
          d="
M1.000000,10.468657 
	C2.341439,16.897482 3.880715,23.763271 4.991576,30.697691 
	C11.272973,69.908539 17.472923,109.132500 23.659151,148.358536 
	C29.488567,185.322083 35.266460,222.293732 41.067406,259.261780 
	C48.830898,308.736664 56.591461,358.212036 64.376625,407.683502 
	C64.581032,408.982452 65.055176,410.238953 65.904854,411.999878 
	C80.961449,426.954407 95.516716,441.424286 110.240067,456.182587 
	C124.296997,470.300598 138.185852,484.130249 152.087524,498.222168 
	C152.066910,498.656281 152.033463,498.828156 152.000000,499.000000 
	C101.701096,499.000000 51.402187,499.000000 1.000000,499.000000 
	C1.000000,336.312439 1.000000,173.624878 1.000000,10.468657 
z"
        />
        <path
          fill="#FEFEFE"
          opacity="1.000000"
          stroke="none"
          d="
M401.174286,5.327666 
	C396.023712,5.219829 390.873230,5.024323 385.722534,5.018627 
	C356.060425,4.985820 326.397919,5.058620 296.736450,4.913243 
	C293.270569,4.896256 291.509155,6.025650 289.828400,9.041391 
	C263.498535,56.284119 237.027481,103.448166 210.579712,150.625168 
	C210.012024,151.637756 209.335602,152.589371 208.360382,153.256073 
	C205.014389,133.921722 202.051270,114.895325 198.963684,95.889160 
	C198.690567,94.207893 197.994888,92.271790 196.838043,91.106964 
	C183.383057,77.559082 169.801636,64.136757 156.185898,50.767372 
	C156.118118,50.853554 156.295837,50.982014 156.217728,50.672413 
	C155.797485,50.142879 155.455338,49.922947 155.037170,49.382107 
	C153.562103,47.655472 152.170959,46.241779 150.762772,44.845264 
	C137.893692,32.082870 125.021629,19.323456 112.150444,6.563173 
	C110.550690,6.043805 108.952034,5.075067 107.351021,5.071142 
	C72.213272,4.985011 37.075256,5.005901 1.468648,5.003158 
	C1.000000,3.667875 1.000000,2.335750 1.000000,1.001813 
	C160.354233,1.000000 319.708466,1.000000 479.531342,1.000001 
	C475.330902,23.173883 470.661804,45.347767 465.630554,67.822449 
	C459.131226,62.101109 452.994110,56.078953 446.719910,49.755375 
	C432.866913,35.810287 419.164398,22.153063 405.412384,8.545796 
	C404.162262,7.308806 402.594513,6.392760 401.174286,5.327666 
z"
        />
        <path
          fill="#FFFFFF"
          opacity="1.000000"
          stroke="none"
          d="
M872.000000,187.531342 
	C871.390991,185.857315 870.560669,183.750458 870.207031,181.566422 
	C866.418152,158.166122 856.139771,138.064148 839.508911,121.235046 
	C832.403137,114.044548 825.058350,107.090302 817.417114,99.638123 
	C813.011536,95.160416 809.010986,91.073318 804.880615,86.678085 
	C791.408997,73.054749 778.067139,59.739563 764.725281,46.424370 
	C764.725281,46.424370 764.739685,46.315311 764.644104,46.146343 
	C764.548523,45.977375 764.274475,45.878857 764.274475,45.878857 
	C742.687744,21.286448 716.324219,5.448396 683.175720,1.361344 
	C745.926636,1.000000 808.853271,1.000000 872.000000,1.000000 
	C872.000000,63.020721 872.000000,125.041702 872.000000,187.531342 
z"
        />
        <path
          fill="#676F6E"
          opacity="1.000000"
          stroke="none"
          d="
M152.468658,499.000000 
	C152.033463,498.828156 152.066910,498.656281 152.551605,498.211700 
	C189.629486,497.943451 226.256119,497.947937 262.882751,497.952423 
	C262.902161,498.127014 262.921600,498.301636 262.970520,498.738098 
	C226.312439,499.000000 189.624878,499.000000 152.468658,499.000000 
z"
        />
        <path
          fill="#6C554D"
          opacity="1.000000"
          stroke="none"
          d="
M764.576538,46.793419 
	C778.067139,59.739563 791.408997,73.054749 804.899414,87.049118 
	C808.346741,93.162689 811.645508,98.597076 814.944214,104.031464 
	C829.287048,131.581482 831.237732,160.499359 824.380554,190.354111 
	C819.987427,209.480545 812.847412,227.472290 802.096313,243.982193 
	C789.207886,263.774414 773.659058,280.942627 754.787598,295.357147 
	C734.780273,310.639282 712.759460,321.536255 688.570374,327.769928 
	C677.551270,330.609558 666.013306,332.411926 654.653015,332.750610 
	C629.859985,333.489807 605.030090,332.990662 580.215210,332.997040 
	C572.909424,332.998932 572.974792,333.012390 571.486267,339.934387 
	C566.558472,362.848755 561.526733,385.740875 556.641113,408.664185 
	C554.047241,420.834412 551.414185,433.007477 549.328857,445.269379 
	C548.520569,450.022308 546.410217,451.079834 542.023010,451.065277 
	C507.882660,450.951843 473.741669,451.040558 439.110352,451.102875 
	C424.470154,437.017548 410.320526,422.896179 396.021301,408.479187 
	C397.933472,407.788300 399.994385,407.053558 402.057007,407.048676 
	C433.552643,406.973907 465.048523,406.993439 496.544342,406.993530 
	C503.820221,406.993561 503.771210,406.987549 505.316772,399.740967 
	C508.924988,382.822968 512.633911,365.926361 516.210571,349.001770 
	C519.958374,331.267120 523.736145,313.536560 527.201965,295.745972 
	C528.211914,290.561798 530.373169,288.777893 535.852844,288.856110 
	C559.344910,289.191559 582.848206,289.258514 606.340515,288.929779 
	C638.904907,288.474091 668.704041,278.903778 696.367981,261.658813 
	C711.056274,252.502533 724.184570,241.624100 735.994873,229.239182 
	C752.094788,212.355835 764.213562,192.769562 772.925354,171.035828 
	C780.845276,151.277664 785.023132,130.916580 783.947815,109.628075 
	C782.908264,89.046448 777.863098,69.596642 766.681091,52.008678 
	C765.733521,50.518215 765.169800,48.783638 764.576538,46.793419 
z"
        />
        <path
          fill="#374447"
          opacity="1.000000"
          stroke="none"
          d="
M263.075348,497.665710 
	C226.256119,497.947937 189.629486,497.943451 152.538773,497.949402 
	C138.185852,484.130249 124.296997,470.300598 110.574203,455.947479 
	C112.553650,455.280579 114.366943,455.013580 116.180435,455.012238 
	C147.831833,454.988464 179.485489,454.804688 211.132614,455.173920 
	C216.928528,455.241516 219.801682,453.130981 222.339935,448.439117 
	C230.154053,433.995087 238.258209,419.707458 246.294769,405.384399 
	C261.260406,378.712097 276.266388,352.062500 291.239014,325.394135 
	C300.586121,308.745575 309.927704,292.093872 319.226379,275.418213 
	C329.340363,257.280396 339.377319,239.099609 349.491699,220.962021 
	C365.209900,192.775391 380.922974,164.585724 396.717651,136.441940 
	C410.380585,112.096603 424.099701,87.782295 437.926208,63.529678 
	C440.588745,58.859482 443.864227,54.538742 446.856964,50.056797 
	C452.994110,56.078953 459.131226,62.101109 465.589203,68.247330 
	C463.006348,82.184189 460.117950,96.000221 457.195374,109.809021 
	C452.863068,130.278625 448.532440,150.748703 444.120758,171.201248 
	C443.672852,173.277695 442.674652,175.235428 441.948914,177.563141 
	C388.740173,273.065430 335.515411,368.253479 282.287018,463.439514 
	C275.957458,474.758362 269.608124,486.066132 263.075348,497.665710 
z"
        />
        <path
          fill="#6C554D"
          opacity="1.000000"
          stroke="none"
          d="
M605.002441,180.999008 
	C589.015747,181.009094 573.028625,180.955917 557.042786,181.075119 
	C553.190796,181.103821 551.677979,180.331482 552.659912,175.911240 
	C557.308899,154.982880 561.560730,133.966324 566.197083,113.035019 
	C566.551575,111.434700 568.563660,109.653511 570.213318,109.021751 
	C572.461060,108.160927 575.100525,108.095520 577.573486,108.086403 
	C595.891541,108.018890 614.215881,107.778198 632.525513,108.177162 
	C640.404968,108.348854 648.482666,109.039192 656.068604,111.026810 
	C670.784607,114.882607 675.993164,131.453644 673.469116,142.935242 
	C672.759155,146.164810 671.624207,149.300919 669.961182,152.735321 
	C651.149902,152.974487 633.060974,152.886963 614.974548,153.061249 
	C613.316711,153.077225 610.515381,154.484619 610.213196,155.725220 
	C608.179138,164.077454 606.676941,172.559204 605.002441,180.999008 
z"
        />
        <path
          fill="#374447"
          opacity="1.000000"
          stroke="none"
          d="
M605.470459,181.022507 
	C606.676941,172.559204 608.179138,164.077454 610.213196,155.725220 
	C610.515381,154.484619 613.316711,153.077225 614.974548,153.061249 
	C633.060974,152.886963 651.149902,152.974487 669.728394,153.005341 
	C665.328918,164.141968 657.463562,172.498383 646.267334,177.176346 
	C641.459656,179.185104 636.112488,180.478394 630.919800,180.858307 
	C622.632446,181.464630 614.269409,181.036652 605.470459,181.022507 
z"
        />
        <path
          fill="#6C554D"
          opacity="1.000000"
          stroke="none"
          d="
M764.164307,46.009239 
	C764.274475,45.878857 764.548523,45.977375 764.601746,46.111267 
	C764.454712,46.209984 764.254456,46.174801 764.164307,46.009239 
z"
        />
        <path
          fill="#6C554D"
          opacity="1.000000"
          stroke="none"
          d="
M446.719910,49.755375 
	C443.864227,54.538742 440.588745,58.859482 437.926208,63.529678 
	C424.099701,87.782295 410.380585,112.096603 396.717651,136.441940 
	C380.922974,164.585724 365.209900,192.775391 349.491699,220.962021 
	C339.377319,239.099609 329.340363,257.280396 319.226379,275.418213 
	C309.927704,292.093872 300.586121,308.745575 291.239014,325.394135 
	C276.266388,352.062500 261.260406,378.712097 246.294769,405.384399 
	C238.258209,419.707458 230.154053,433.995087 222.339935,448.439117 
	C219.801682,453.130981 216.928528,455.241516 211.132614,455.173920 
	C179.485489,454.804688 147.831833,454.988464 116.180435,455.012238 
	C114.366943,455.013580 112.553650,455.280579 110.406128,455.659088 
	C95.516716,441.424286 80.961449,426.954407 66.233994,412.200775 
	C68.155205,411.610229 70.248100,411.040070 72.342064,411.036133 
	C103.992210,410.976440 135.645508,410.765747 167.290039,411.189819 
	C173.196640,411.269012 176.131836,408.889252 178.585114,404.449890 
	C188.076599,387.274384 197.503998,370.063080 207.071915,352.930267 
	C218.417877,332.613525 229.937653,312.393921 241.283203,292.076996 
	C252.778793,271.491364 264.077637,250.795685 275.600189,230.225250 
	C290.868896,202.967087 306.334595,175.819244 321.596100,148.557053 
	C334.103149,126.215286 346.342926,103.723778 358.870331,81.393555 
	C372.135620,57.748062 385.606964,34.218208 398.963531,10.623799 
	C399.775391,9.189673 400.368896,7.631949 401.119141,5.729699 
	C402.594513,6.392760 404.162262,7.308806 405.412384,8.545796 
	C419.164398,22.153063 432.866913,35.810287 446.719910,49.755375 
z"
        />
        <path
          fill="#6C554D"
          opacity="1.000000"
          stroke="none"
          d="
M112.104828,7.022109 
	C125.021629,19.323456 137.893692,32.082870 150.762772,44.845264 
	C152.170959,46.241779 153.562103,47.655472 155.207062,49.618004 
	C155.733917,50.443882 156.014877,50.712952 156.295837,50.982018 
	C156.295837,50.982014 156.118118,50.853554 156.101776,51.231483 
	C157.408569,61.303219 158.651917,71.008728 160.071106,80.688454 
	C163.272766,102.525795 166.547913,124.352402 169.820084,146.179337 
	C171.544876,157.684509 173.179626,169.206146 175.131638,180.673279 
	C176.519821,188.828140 178.369858,196.904358 180.013763,205.015686 
	C170.534546,222.107162 161.055328,239.198654 151.576111,256.290131 
	C151.113785,256.215546 150.651474,256.140991 150.189163,256.066406 
	C148.790161,246.999405 147.424988,237.927048 145.985458,228.866501 
	C142.470535,206.743011 138.849731,184.636124 135.402847,162.502090 
	C130.499710,131.016846 125.713829,99.513336 120.878510,68.017525 
	C118.035065,49.496136 115.181328,30.976307 112.370178,12.450021 
	C112.122177,10.815597 112.156181,9.138382 112.104828,7.022109 
z"
        />
        <path
          fill="#384447"
          opacity="1.000000"
          stroke="none"
          d="
M180.252655,204.773468 
	C178.369858,196.904358 176.519821,188.828140 175.131638,180.673279 
	C173.179626,169.206146 171.544876,157.684509 169.820084,146.179337 
	C166.547913,124.352402 163.272766,102.525795 160.071106,80.688454 
	C158.651917,71.008728 157.408569,61.303219 156.169556,51.145302 
	C169.801636,64.136757 183.383057,77.559082 196.838043,91.106964 
	C197.994888,92.271790 198.690567,94.207893 198.963684,95.889160 
	C202.051270,114.895325 205.014389,133.921722 208.043991,153.334702 
	C205.188873,159.502380 202.483231,165.378464 199.373810,171.032501 
	C193.197388,182.263474 186.798508,193.372086 180.252655,204.773468 
z"
        />
        <path
          fill="#53514A"
          opacity="1.000000"
          stroke="none"
          d="
M814.974060,103.675049 
	C811.645508,98.597076 808.346741,93.162689 805.029236,87.357262 
	C809.010986,91.073318 813.011536,95.160416 817.077148,99.589752 
	C816.429443,101.060875 815.716675,102.189751 814.974060,103.675049 
z"
        />
        <path
          fill="#384447"
          opacity="1.000000"
          stroke="none"
          d="
M156.217728,50.672417 
	C156.014877,50.712952 155.733917,50.443882 155.283081,49.938919 
	C155.455338,49.922947 155.797485,50.142879 156.217728,50.672417 
z"
        />
      </svg> */}
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <a href="https://placementconnect.in/">{logo}</a>;
}
