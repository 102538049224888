import React from 'react';
import { Dialog, DialogContent, DialogTitle, Typography, Box, Button, Grid } from '@mui/material';
import { styled } from '@mui/system';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import InfoIcon from '@mui/icons-material/Info';
import SchoolIcon from '@mui/icons-material/School';
import BusinessIcon from '@mui/icons-material/Business';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: 16,
    padding: theme.spacing(4),
    maxWidth: 800,
    width: '90%',
  },
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: theme.spacing(2),
}));

const StyledVerifiedUserIcon = styled(VerifiedUserIcon)(({ theme }) => ({
  fontSize: 64,
  color: theme.palette.primary.main,
}));

const StyledInfoIcon = styled(InfoIcon)(({ theme }) => ({
  fontSize: 24,
  color: theme.palette.info.main,
  marginRight: theme.spacing(1),
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(2),
  marginTop: theme.spacing(3),
}));

const FeatureCard = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[2],
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
}));

const simpleCipher = (input, key) => {
    let output = '';
    for (let i = 0; i < input.length; i += 1) {
      const inputChar = input.charCodeAt(i);
      const keyChar = key.charCodeAt(i % key.length);
      output += String.fromCharCode((inputChar + keyChar) % 256);
    }
    return output;
  };
  

const generateBlockID = (email) => {
    const key = 'PlacementConnect'; // You can change this key
    const encrypted = simpleCipher(email, key);
    return btoa(encrypted).replace(/=/g, '').slice(0, 12); // Remove padding and limit to 12 characters
  };

const AccountPendingBanner = ({ user, logout }) => {
  const blockID = generateBlockID(user.email);

  return (
    <StyledDialog open aria-labelledby="account-verification-dialog-title">
      <DialogTitle id="account-verification-dialog-title">
        <IconWrapper>
          <StyledVerifiedUserIcon />
        </IconWrapper>
        <Typography variant="h4" align="center" gutterBottom>
          Account Verification in Progress
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" paragraph>
          Welcome to PlacementConnect, a platform designed to connect Placement Officers with Campus Recruiters, tailored for India's specific needs by VarsityPro Group.
        </Typography>

        <Typography variant="h6" gutterBottom>
          How PlacementConnect Works
        </Typography>
        <Grid container spacing={3} sx={{ mb: 3 }}>
          <Grid item xs={12} sm={6}>
            <FeatureCard>
              <SchoolIcon sx={{ fontSize: 40, mb: 1, color: 'primary.main' }} />
              <Typography variant="subtitle1" gutterBottom>
                For TPOs
              </Typography>
              <Typography variant="body2">
                Connect with active campus recruiters and streamline your placement process.
              </Typography>
            </FeatureCard>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FeatureCard>
              <BusinessIcon sx={{ fontSize: 40, mb: 1, color: 'primary.main' }} />
              <Typography variant="subtitle1" gutterBottom>
                For Recruiters
              </Typography>
              <Typography variant="body2">
                Post your recruitment needs and connect directly with university placement cells.
              </Typography>
            </FeatureCard>
          </Grid>
        </Grid>

        <Typography variant="body1" paragraph>
          In last 2 months, PlacementConnect has facilitated over 300 campus drives with 100+ recruiters in India, creating a trusted community for campus recruitment.
        </Typography>

        <Box display="flex" alignItems="center" bgcolor="info.light" p={2} borderRadius={2} mb={2}>
          <StyledInfoIcon />
          <Typography variant="body2" color="info.dark">
            Your account is currently under verification. This process typically takes less than 30 minutes. We appreciate your patience as we ensure the integrity of our platform.
          </Typography>
        </Box>

        <Typography variant="body1" paragraph>
          To maintain a high-quality community, PlacementConnect is exclusively for:
        </Typography>
        <Typography component="div" variant="body1" paragraph>
          <ul>
            <li>TPOs, University Professors, and Career Development Cell representatives</li>
            <li>HR Professionals and Campus Recruiters from verified companies</li>
          </ul>
        </Typography>

        <Typography variant="body1" paragraph>
          If you haven't received access after 30 minutes, please email support@placementconnect.in with the following information:
        </Typography>
        <Typography variant="body2" paragraph>
          Email: {user.email}
          <br />
          BlockID: {blockID}
        </Typography>

        <ButtonContainer>
          <Button
            variant="contained"
            color="primary"
            href="mailto:support@placementconnect.in"
          >
            Contact Support
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={logout}
          >
            Logout
          </Button>
        </ButtonContainer>
      </DialogContent>
    </StyledDialog>
  );
};

export default AccountPendingBanner;