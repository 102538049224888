import React, { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import {
  Box,
  Typography,
  Button,
  Modal,
  CircularProgress,
  Alert,
  useTheme,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { closeModal } from '../../../../redux/slices/authModal';
import { VerifyCodeForm } from '.';
import axios from '../../../../utils/axios';
import Iconify from '../../../Iconify';
import useAuth from '../../../../hooks/useAuth';

const VerificationSchema = Yup.object().shape({
  otp: Yup.string()
    .matches(/^[0-9]{6}$/, 'OTP must be 6 digits')
    .required('OTP is required'),
});

const changeMobileSchema = Yup.object().shape({
  newMobile: Yup.string().matches(/^[0-9]{10}$/, 'Must be exactly 10 digits').required('Mobile number is required'),
});

export default function VerifyOTPModal( ) {
  const { user } = useAuth();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [retryTimer, setRetryTimer] = useState(30);
  const [retryCount, setRetryCount] = useState(0);
  const [isRetryDisabled, setIsRetryDisabled] = useState(false);
  const [changeMobileDialogOpen, setChangeMobileDialogOpen] = useState(false);
  const [isNumberConfirmed, setIsNumberConfirmed] = useState(false);

  const onClose = () => {
    dispatch(closeModal());
  };

  const verificationForm = useForm({
    resolver: yupResolver(VerificationSchema),
    defaultValues: { otp: ['', '', '', '', '', ''] },
  });

  const changeMobileForm = useForm({
    resolver: yupResolver(changeMobileSchema),
    defaultValues: { newMobile: '' },
  });

  useEffect(() => {
    if (isNumberConfirmed) {
      const intervalId = setInterval(() => {
        setRetryTimer((prevTimer) => {
          if (prevTimer <= 0) {
            setIsRetryDisabled(false);
            return 30;
          }
          return prevTimer - 1;
        });
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [isNumberConfirmed]);

  const handleClose = () => {
    onClose();
    dispatch(closeModal());
  };

  const sendOTP = async () => {
    try {
      const response = await axios.get('/api/v1/accounts/user/request-otp/');
      if (response.status === 200) {
        enqueueSnackbar('OTP sent to your mobile.', { variant: 'success' });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(`Error: ${error?.message}`, { variant: 'error' });
      throw error;
    }
  };

  const handleVerifyOTP = async (data) => {
    setLoading(true);
    setError('');
    try {
      const otp = Object.values(data).join('');
      const response = await axios.post('/api/v1/accounts/user/verify-otp/', { otp });

      if (response.status === 200) {
        enqueueSnackbar('OTP verification successful!', { variant: 'success' });
        handleClose();
        window.location.reload();
      }
    } catch (err) {
      setError(err.response?.data?.message || 'An error occurred during OTP verification');
    } finally {
      setLoading(false);
    }
  };

  const handleResendOTP = async () => {
    if (!isRetryDisabled && retryCount < 4) {
      try {
        await sendOTP();
        setRetryCount((prevCount) => prevCount + 1);
        setIsRetryDisabled(true);
        setRetryTimer(30);
      } catch (error) {
        console.error(error);
        enqueueSnackbar('Something went wrong', { variant: 'error' });
      }
    } else {
      enqueueSnackbar('Maximum retry attempts reached.', { variant: 'error' });
    }
  };

  const handleConfirmNumber = async () => {
    setIsNumberConfirmed(true);
    await sendOTP();
  };

  const handleChangeMobileOpen = () => {
    setChangeMobileDialogOpen(true);
  };

  const handleChangeMobileClose = () => {
    setChangeMobileDialogOpen(false);
    changeMobileForm.reset();
  };

  const handleChangeMobile = async (data) => {
    try {
      setLoading(true);
      const response = await axios.post('/api/v1/accounts/user/change-number/', { mobile: data.newMobile });
      if (response.status === 200) {
        enqueueSnackbar('Mobile number changed successfully. Please verify the new number.', { variant: 'success' });
        window.location.reload();
        handleChangeMobileClose();
        setIsNumberConfirmed(false);
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(`Error: ${error?.response?.data?.message || 'Failed to change mobile number'}`, {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
        <Box
          // sx={{
          //   position: 'absolute',
          //   top: '50%',
          //   left: '50%',
          //   transform: 'translate(-50%, -50%)',
          //   bgcolor: 'background.paper',
          //   boxShadow: 24,
          //   borderRadius: 2,
          //   p: 3,
          //   width: '90%',
          //   maxWidth: 'sm',
          // }}
        >
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>

          <Box sx={{ textAlign: 'center', mb: 3 }}>
            <Iconify icon="mdi:cellphone-message" sx={{ fontSize: 48, color: theme.palette.primary.main, mb: 2 }} />
            <Typography variant="h6" gutterBottom>
              Verify Your Account
            </Typography>
            {!isNumberConfirmed ? (
              <>
                <Typography variant="body2" sx={{ color: 'text.secondary', mb: 2 }}>
                  Is this your correct mobile number?
                </Typography>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  {user?.mobile}
                </Typography>
                <Button variant="contained" onClick={handleConfirmNumber} fullWidth>
                  Yes, it's correct
                </Button>
                <Button variant="outlined" onClick={handleChangeMobileOpen} fullWidth sx={{ mt: 1 }}>
                  No, change number
                </Button>
              </>
            ) : (
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                We've sent a 6-digit code to {user?.mobile}
              </Typography>
            )}
          </Box>

          {isNumberConfirmed && (
            <>
              <VerifyCodeForm handleVerifyOTP={handleVerifyOTP} />

              <Box display="flex" justifyContent="space-between" mt={3}>
                <Button
                  onClick={handleResendOTP}
                  variant="outlined"
                  disabled={isRetryDisabled}
                  startIcon={<Iconify icon="mdi:refresh" />}
                >
                  Resend OTP {isRetryDisabled && `(${retryTimer}s)`}
                </Button>
                <Button
                  variant="outlined"
                  onClick={handleChangeMobileOpen}
                  startIcon={<Iconify icon="mdi:cellphone-settings" />}
                >
                  Change Mobile Number
                </Button>
              </Box>

              {error && (
                <Alert severity="error" sx={{ mt: 2 }}>
                  {error}
                </Alert>
              )}

              {loading && (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                  <CircularProgress size={24} />
                </Box>
              )}
            </>
          )}
        </Box>
      <Dialog open={changeMobileDialogOpen} onClose={handleChangeMobileClose} maxWidth="md" fullWidth>
        <DialogTitle>
          Change Mobile Number
          <IconButton
            aria-label="close"
            onClick={handleChangeMobileClose}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <form onSubmit={changeMobileForm.handleSubmit(handleChangeMobile)}>
          <DialogContent>
            <Typography variant="body2" sx={{ mb: 2 }}>
              Enter your new mobile number below. You'll receive a new OTP for verification.
            </Typography>
            <Controller
              name="newMobile"
              control={changeMobileForm.control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="New Mobile Number"
                  variant="outlined"
                  error={!!error}
                  helperText={error?.message}
                  InputProps={{
                    startAdornment: (
                      <Typography variant="body1" sx={{ mr: 1 }}>
                        +91
                      </Typography>
                    ),
                  }}
                />
              )}
            />
          </DialogContent>
          <DialogActions sx={{ px: 3, pb: 3 }}>
            <Button onClick={handleChangeMobileClose} variant="outlined">
              Cancel
            </Button>
            <Button type="submit" variant="contained" disabled={loading}>
              {loading ? <CircularProgress size={24} /> : 'Change Number'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}