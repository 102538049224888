// routes
import { PATH_AUTH, PATH_DOCS, PATH_PAGE } from '../../../routes/paths';
// components
import { PATH_AFTER_LOGIN } from '../../../config';
// components
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22,
};

const menuConfig = [
  // {
  //   title: 'Listings',
  //   children: [
  //     {
  //       title: 'University Listings',
  //       path: '/university/listings',
  //     },
  //     {
  //       title: 'Recruiter Listings',
  //       path: '/',
  //     },
  //   ],
  // },
  // {
    
  //   title: 'Directory',
  //   path: '/directory',
  // },
  // {
  //   title: 'Dashboard',
  //   path: '/dashboard',
  // },
];




export default menuConfig;
