import React from 'react';
import { Dialog, DialogContent, DialogTitle, Typography, Box, Button } from '@mui/material';
import { styled } from '@mui/system';
import LockIcon from '@mui/icons-material/Lock';
import InfoIcon from '@mui/icons-material/Info';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: 16,
    padding: theme.spacing(4),
    maxWidth: 600,
    width: '90%',
  },
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: theme.spacing(2),
}));

const StyledLockIcon = styled(LockIcon)(({ theme }) => ({
  fontSize: 64,
  color: theme.palette.warning.main,
}));

const StyledInfoIcon = styled(InfoIcon)(({ theme }) => ({
  fontSize: 24,
  color: theme.palette.info.main,
  marginRight: theme.spacing(1),
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(2),
  marginTop: theme.spacing(3),
}));


const simpleCipher = (input, key) => {
    let output = '';
    for (let i = 0; i < input.length; i += 1) {
      const inputChar = input.charCodeAt(i);
      const keyChar = key.charCodeAt(i % key.length);
      output += String.fromCharCode((inputChar + keyChar) % 256);
    }
    return output;
  };
  

const generateBlockID = (email) => {
    const key = 'PlacementConnect'; // You can change this key
    const encrypted = simpleCipher(email, key);
    return btoa(encrypted).replace(/=/g, '').slice(0, 12); // Remove padding and limit to 12 characters
  };

const AccountLockedBanner = ({ user, logout }) => {
  const blockID = generateBlockID(user.email);

  return (
    <StyledDialog open aria-labelledby="account-locked-dialog-title">
      <DialogTitle id="account-locked-dialog-title">
        <IconWrapper>
          <StyledLockIcon />
        </IconWrapper>
        <Typography variant="h4" align="center" gutterBottom>
          Account Access Restricted
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" paragraph>
          We regret to inform you that your account access has been restricted. PlacementConnect is a closed community exclusively for Campus Recruiters and Placement Officers.
        </Typography>

        <Typography variant="body1" paragraph>
          Our system has detected that your profile may not align with our community's focus. We understand that mistakes can happen, and we want to ensure that everyone who should have access does.
        </Typography>

        <Box display="flex" alignItems="center" bgcolor="info.light" p={2} borderRadius={2} mb={2}>
          <StyledInfoIcon />
          <Typography variant="body2" color="info.dark">
            If you believe you should have access to PlacementConnect, we encourage you to appeal this decision. We'd be delighted to welcome you to our community if there's been an error.
          </Typography>
        </Box>

        <Typography variant="body1" paragraph>
          To appeal, please email support@placementconnect.in with the following information:
        </Typography>
        <Typography variant="body2" paragraph>
          1. Your full name
          <br />
          2. Your professional role (Campus Recruiter or Placement Officer)
          <br />
          3. The institution or company you represent
          <br />
          4. A brief explanation of why you believe you should have access
          <br />
          5. Your account email: {user.email}
          <br />
          6. Your BlockID: {blockID}
        </Typography>

        <Typography variant="body1" paragraph>
          Please note that this is a rare occurrence, and we genuinely hope to resolve any misunderstandings quickly. We value each member of our community and strive to maintain a platform that best serves Campus Recruiters and Placement Officers.
        </Typography>

        <Typography variant="body1" paragraph>
          Thank you for your understanding and patience as we work to maintain the integrity of the PlacementConnect community.
        </Typography>

        <ButtonContainer>
          <Button
            variant="contained"
            color="primary"
            href="mailto:support@placementconnect.in"
          >
            Contact Support
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={logout}
          >
            Logout
          </Button>
        </ButtonContainer>
      </DialogContent>
    </StyledDialog>
  );
};

export default AccountLockedBanner;